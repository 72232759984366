import { defineMessages } from "@formatjs/intl";

export const commonMessages = defineMessages({
  Ok: { defaultMessage: "Ok", description: "Generic ok for confirmation. Must be less than 6 characters " },
  Okay: { defaultMessage: "Okay", description: "Slightly longer generic ok for confirmation. Must be less than 10 characters" },
  Back: { defaultMessage: "Back", description: "Generic navigation back. Must be 6 characters or less." },
  Cancel: { defaultMessage: "Cancel", description: "Generic navigation cancel. Must be less than 6 characters" },
  Canceled: { defaultMessage: "Canceled", description: "Something has been canceled" },
  Archive: { defaultMessage: "Archive", description: "Generic for archiving a message" },
  NewEvent: { defaultMessage: "New Event", description: "Generic for creating new events" },
  Close: { defaultMessage: "Close", description: "Generic navigation close. Must be less than 6 characters." },
  Next: { defaultMessage: "Next", description: "Generic navigation next. Must be less than 6 characters." },
  Go: { defaultMessage: "Go", description: "Generic navigation Go. Must be less than 6 characters." },
  Finish: { defaultMessage: "Finish", description: "finish or complete. Must be less than 6 characters." },
  Done: { defaultMessage: "Done", description: "Generic navigation done. Must be less than 6 characters." },
  Apply: { defaultMessage: "Apply", description: "Generic Apply as in to apply changes." },
  Success: { defaultMessage: "Success", description: "An action was a success. Must be less than 10 characters." },
  Save: { defaultMessage: "Save", description: "Generic save as in saving changes. Must be less than 6 characters." },
  SavedGeneric: { defaultMessage: "Saved", description: "Generic saved as in changes were saved" },
  Continue: { defaultMessage: "Continue", description: "Generic navigation continue. Must be less than 10 characters." },
  Merge: { defaultMessage: "Merge", description: "Merge as in combine 2 players" },
  Send: { defaultMessage: "Send", description: "send like to send a message or email" },
  Sent: { defaultMessage: "Sent", description: "sent as in past tense for send, like sent a message or email" },
  Yes: { defaultMessage: "Yes", description: "Generic yes. Must be less than 6 characters." },
  No: { defaultMessage: "No", description: "Generic no. Must be less than 6 characters." },
  "N/A": { defaultMessage: "N/A", description: "Generic N/A for Not Applicable. Must be three characters" },
  Final: { defaultMessage: "Final", description: "As in the Final score of the game" },
  Live: { defaultMessage: "Live", description: "As in the the game is live, or currently being played" },
  IsRequired: { defaultMessage: "Is Required", description: "Generic is required for forms" },
  Email: { defaultMessage: "Email", description: "email" },
  Password: { defaultMessage: "Password", description: "Password" },
  Details: { defaultMessage: "Details", description: "Details" },
  Total: { defaultMessage: "Total", description: "Total" },
  Position: {
    defaultMessage: "Position",
    description: "a player in a sport plays a position or role on the field (like defender, forward, midfielder, etc.)"
  },
  Hour: { defaultMessage: "Hour", description: "Hour as in 60 minutes" },
  Name: { defaultMessage: "Name", description: "Generic name for forms" },
  ShortName: { defaultMessage: "Short Name", description: "Abbreviated name" },
  FirstName: { defaultMessage: "First Name", description: "Generic first name for forms" },
  LastName: { defaultMessage: "Last Name", description: "Generic last name for forms" },
  JerseyNumber: { defaultMessage: "Jersey Number", description: "Generic jersey number for forms" },
  Create: { defaultMessage: "Create", description: "Generic create" },
  CreatePlayer: { defaultMessage: "Create Player", description: "Create a player" },
  CreateTeam: { defaultMessage: "Create Team", description: "Create a Team" },
  JoinTeam: { defaultMessage: "Join Team", description: "Join a Team" },
  BirthYear: { defaultMessage: "Birth Year", description: "Generic birth year" },
  DateOfBirth: { defaultMessage: "Date of Birth", description: "Generic date of Birth" },
  Gender: { defaultMessage: "Gender", description: "Generic gender" },
  Choose: { defaultMessage: "Choose", description: "Generic choose. Must be less than 6 characters." },
  None: { defaultMessage: "None", description: "Generic none" },
  Skip: { defaultMessage: "Skip", description: "Generic skip. Must be less than 6 characters." },
  Guest: { defaultMessage: "Guest", description: "a guest is someone who is just on the team temporarily" },
  Invite: { defaultMessage: "Invite", description: "Generic invite" },
  InviteOthers: { defaultMessage: "Invite Others", description: "invite other people" },
  State: { defaultMessage: "State", description: "Generic state for location" },
  Region: { defaultMessage: "Region", description: "Generic region for location" },
  Province: { defaultMessage: "Province", description: "Generic province for location" },
  Country: { defaultMessage: "Country", description: "Generic country for location" },
  City: { defaultMessage: "City", description: "Generic City for location" },
  Address: { defaultMessage: "Address", description: "Generic Address for location" },
  Address1: { defaultMessage: "Address 1", description: "Generic Address 1 for location" },
  Address2: { defaultMessage: "Address 2", description: "Generic Address 2 for location" },
  ZipCode: { defaultMessage: "Zip Code", description: "Generic zip code for location" },
  RegionCode: { defaultMessage: "Region Code", description: "Generic region code for location" },
  TimeZone: { defaultMessage: "Time Zone", description: "time zone for a location" },
  Location: { defaultMessage: "Location", description: "Generic location" },
  Girl: { defaultMessage: "Girl", description: "Generic girl for gender" },
  Boy: { defaultMessage: "Boy", description: "Generic boy for gender" },
  Girls: { defaultMessage: "Girls", description: "Generic girls for gender" },
  Boys: { defaultMessage: "Boys", description: "Generic boys for gender" },
  Mixed: { defaultMessage: "Mixed", description: "Generic mixed for gender" },
  Guardian: { defaultMessage: "Guardian", description: "Generic guardian" },
  Guardians: { defaultMessage: "Guardians", description: "Generic guardians" },
  Parent: { defaultMessage: "Parent", description: "Generic parent" },
  Role: { defaultMessage: "Role", description: "Generic role" },
  Roles: { defaultMessage: "Roles", description: "Generic roles" },
  HighSchool: { defaultMessage: "High School", description: "Generic high school for team type" },
  Club: { defaultMessage: "Club", description: "Generic club for team type" },
  Competition: { defaultMessage: "Competition", description: "Generic competition for team type" },
  Recreation: { defaultMessage: "Recreation", description: "Generic recreation for team type" },
  Academy: { defaultMessage: "Academy", description: "Generic academy for team type" },
  College: { defaultMessage: "College", description: "Generic college for team type" },
  Adult: { defaultMessage: "Adult", description: "Generic adult for team type" },
  Individual: {
    defaultMessage: "Individual",
    description: "A single person, also used as a title for the plan that covers 1 person"
  },
  Family: {
    defaultMessage: "Family",
    description: "A family"
  },
  Team: { defaultMessage: "Team", description: "team as in soccer team" },
  Opponent: { defaultMessage: "Opponent", description: "opponent in a game" },
  OpponentName: { defaultMessage: "Opponent Name", description: "name of the opponent" },
  TeamType: { defaultMessage: "Team Type", description: "Generic team type" },
  Delete: { defaultMessage: "Delete", description: "Generic delete" },
  Edit: { defaultMessage: "Edit", description: "Generic edit" },
  Find: { defaultMessage: "Find", description: "Generic find" },
  Optional: { defaultMessage: "Optional", description: "Generic optional" },
  Default: { defaultMessage: "Default", description: "the default options" },
  Color: { defaultMessage: "Color", description: "Generic color" },
  Settings: { defaultMessage: "Settings", description: "Generic settings" },
  Loading: { defaultMessage: "Loading", description: "loading, processing" },
  Leave: { defaultMessage: "Leave", description: "leave a conversation or a team" },
  Clear: { defaultMessage: "Clear", description: "clear something or delete something" },
  Confirm: { defaultMessage: "Confirm", description: "confirm or approve something" },
  Submit: { defaultMessage: "Submit", description: "Generic submit" },
  Active: { defaultMessage: "Active", description: "Generic active" },
  Inactive: { defaultMessage: "Inactive", description: "Generic inactive" },
  Closed: { defaultMessage: "Closed", description: "Generic closed" },
  Terms: { defaultMessage: "Terms", description: "Terms as in terms and conditions" },
  Conditions: { defaultMessage: "Conditions", description: "conditions as in terms and conditions" },
  And: { defaultMessage: "And", description: `as in this "and" that` },
  Warning: { defaultMessage: "Warning", description: "generic warning" },
  Review: { defaultMessage: "Review", description: "review or look over something" },
  Vote: { defaultMessage: "Vote", description: "Generic vote" },
  View: { defaultMessage: "View", description: "Generic view" },
  Attended: { defaultMessage: "Attended", description: "as in a player attended an event" },
  Tardy: { defaultMessage: "Tardy", description: "as in late for an event" },
  MissedEvent: { defaultMessage: "Missed", description: "as in did not attend an event" },
  Unknown: { defaultMessage: "Unknown", description: "the status of something is unknown" },
  AttendedOneLetter: { defaultMessage: "A", description: `First letter of "Attended" as in a player attended an event` },
  TardyOneLetter: { defaultMessage: "T", description: `First letter of "Tardy" as in a late for an event` },
  MissedEventOneLetter: { defaultMessage: "M", description: `First letter of "Missed" as in a player did not attend an event` },
  UnknownOneLetter: { defaultMessage: "U", description: `First letter of "Unknown" as in the status of something is unknown` },
  Type: { defaultMessage: "Type", description: "the Type of an event" },
  Date: { defaultMessage: "Date", description: "the date of an event" },
  Memo: { defaultMessage: "Memo", description: "A memo as in a note" },
  StartDate: { defaultMessage: "Start Date", description: "the start date" },
  EndDate: { defaultMessage: "End Date", description: "the end date" },
  DownPayment: { defaultMessage: "Down Payment", description: "The amount due when setting up a payment plan" },
  DueDate: { defaultMessage: "Due Date", description: "the Due date" },
  Due: { defaultMessage: "Due", description: "As in a payment is due" },
  Paid: { defaultMessage: "Paid", description: "As in how much money has been paid" },
  Credit: { defaultMessage: "Credit", description: "As in a credit for a payment" },
  Credits: { defaultMessage: "Credits", description: "As in credits for a payment" },
  Payments: { defaultMessage: "Payments", description: "Payments" },
  Unpaid: { defaultMessage: "Unpaid", description: "As in an invoice is unpaid" },
  Partial: { defaultMessage: "Partial", description: "As in a partial payment" },
  Failed: { defaultMessage: "Failed", description: "As in a failed payment" },
  FailedPayment: { defaultMessage: "Failed Payment", description: "As in a failed payment" },
  Scheduled: { defaultMessage: "Scheduled", description: "As in a scheduled payment" },
  PastDue: { defaultMessage: "Past Due", description: "As in a Past Due payment" },
  Issue: { defaultMessage: "Issue", description: "As in a payment issue" },
  InProgress: { defaultMessage: "In Progress", description: "As in a payment plan is in progress" },
  DueToday: { defaultMessage: "Due Today", description: "As in a payment is due today" },
  FilterVerb: { defaultMessage: "Filter", description: "filter results" },
  Sort: { defaultMessage: "Sort", description: "sort or order a list" },
  Filters: { defaultMessage: "Filters", description: "filters for results" },
  ViewAll: { defaultMessage: "View All", description: "Generic view all" },
  ChangeVotes: { defaultMessage: "Change Votes", description: "Generic change votes" },
  CalendarEvent: { defaultMessage: "Event", description: "a single calendar event" },
  CalendarEvents: { defaultMessage: "Events", description: "multiple calendar events" },
  Show: { defaultMessage: "Show", description: "make something appear or show up" },
  Change: { defaultMessage: "Change", description: "change or edit something" },
  Hide: { defaultMessage: "Hide", description: "hide something or make it disappear" },
  Note: { defaultMessage: "Note", description: "a note or short messages about something" },
  Comment: { defaultMessage: "Comment", description: "Verb to comment on something" },
  Notes: { defaultMessage: "Notes", description: "notes or a short message about something" },
  Stats: { defaultMessage: "Stats", description: "short word for statistics" },
  Events: { defaultMessage: "Events", description: "calendar events" },
  Teams: { defaultMessage: "Teams", description: "multiple soccer teams" },
  Chat: { defaultMessage: "Chat", description: "noun version of chat. Name of a tab in the app" },
  ChatAll: { defaultMessage: "Chat All", description: "Chat all people in a list. Try to keep as short as possible" },
  Organization: { defaultMessage: "Organization", description: "a group or club of teams" },
  Organizations: { defaultMessage: "Organizations", description: "a group or club of teams" },
  OrgShort: { defaultMessage: "Org", description: "short for the word Organization" },
  PhoneNumber: { defaultMessage: "Phone Number", description: "phone number" },
  Month: { defaultMessage: "Month", description: "month (calendar)" },
  Week: { defaultMessage: "Week", description: "week (calendar)" },
  Day: { defaultMessage: "Day", description: "day (calendar)" },
  Error: { defaultMessage: "Error", description: "generic error message" },
  Highlights: { defaultMessage: "Highlights", description: "highlights from a game, as in the best stats" },
  Rankings: { defaultMessage: "Rankings", description: "rankings for MVPs or awards" },
  Gallery: { defaultMessage: "Gallery", description: "gallery for photos and videos" },
  TapRowToLearnMoreAboutStats: {
    defaultMessage: "Tap row to learn about stat",
    description: "telling the user to tap the row to learn more about a stat"
  },
  ByHalf: { defaultMessage: "By Half", description: "showing stats by half" },
  ByPosition: { defaultMessage: "By Position", description: "showing stats by position" },
  ByPlayer: { defaultMessage: "By Player", description: "showing stats by player" },
  Race: { defaultMessage: "Race", description: "Competitive event for biking, running, etc." },
  Game: { defaultMessage: "Game", description: "Competitive game in a sporting event" },
  Games: { defaultMessage: "Games", description: "Competitive games in a sporting event" },
  Practice: { defaultMessage: "Practice", description: "practice or training" },
  Scrimmage: { defaultMessage: "Scrimmage", description: "scrimmage of practice game" },
  Other: { defaultMessage: "Other", description: "other type of event besides game, practice, and scrimmage" },
  OtherSport: { defaultMessage: "Other Sport", description: "other type of sport than the ones listed" },
  Notifications: { defaultMessage: "Notifications", description: "Notifications" },
  EnterScore: { defaultMessage: "Enter Score", description: "enter a score for a game" },
  RecordStats: { defaultMessage: "Record Stats", description: "record or enter stats for a game" },
  TapToBeginRecording: { defaultMessage: "Tap to begin recording", description: "text for a button to begin recording stats" },
  Enter: { defaultMessage: "Enter", description: "enter or record stats" },
  Awards: { defaultMessage: "Awards", description: "awards given to players based on their stats" },
  GameAwards: { defaultMessage: "Game Awards", description: "awards given to players based on their stats for a game" },
  MVPs: { defaultMessage: "MVPs", description: "Most Valuable Players" },
  Evaluations: { defaultMessage: "Evaluations", description: "evaluations for players and coaches" },
  Evaluate: { defaultMessage: "Evaluate", description: "to evaluate someone" },
  Record: { defaultMessage: "Record", description: "record or enter stats for a game" },
  Resume: { defaultMessage: "Resume", description: "resume recording or entering stats for a game" },
  About: { defaultMessage: "About", description: "Relating to, as in this book is about dinosaure" },
  UpgradeToUnlock: {
    defaultMessage: "Upgrade to unlock",
    description: "a message used in our gating and billing to tell the user they need to upgrade to unlock a feature"
  },
  Upgrade: {
    defaultMessage: "Upgrade",
    description: "a message used in our gating and billing to tell the user they need to upgrade to unlock a feature"
  },
  UpgradeNow: {
    defaultMessage: "Upgrade Now",
    description: "a message used in our gating and billing to tell the user they need to upgrade to unlock a feature"
  },
  StartFreeTrial: {
    defaultMessage: "Start a 14 day free trial",
    description: "message to tell the user they can start a 14 day free trial of Ollie pro"
  },
  Share: { defaultMessage: "Share", description: "share or send something" },
  Export: { defaultMessage: "Export", description: "export something" },
  Copy: { defaultMessage: "Copy", description: "copy something" },
  CopyNoun: { defaultMessage: "Copy", description: "Noun. As in a copy of something" },
  Options: { defaultMessage: "Options", description: "options" },
  Navigate: { defaultMessage: "Navigate", description: "navigate to somewhere" },
  Copied: { defaultMessage: "Copied", description: "tell the user that something was copied" },
  LearnMore: { defaultMessage: "Learn More", description: "learn more about something" },
  Sponsors: {
    defaultMessage: "Sponsors",
    description:
      "sponsors are companies or people that pay a team to support them and get advertisement through the app in return"
  },
  Checklists: { defaultMessage: "Checklists", description: "lists of items to complete" },
  Leaders: { defaultMessage: "Leaders", description: "players who lead in stats" },
  Trends: { defaultMessage: "Trends", description: "trends are patterns over time looking at stats" },
  TeamSeasonStats: { defaultMessage: "Team Season Stats", description: "season stats for a team" },
  SeasonStats: { defaultMessage: "Season Stats", description: "season stats" },
  PlayerSeasonStats: { defaultMessage: "Player Season Stats", description: "season stats for a player" },
  OwnPlayerSeasonStats: { defaultMessage: "Own Player Season Stats", description: "season stats for your own player" },
  PlayerStats: { defaultMessage: "Player Stats", description: "stats for a player" },
  PlayerGameStats: { defaultMessage: "Player Game Stats", description: "stats for a game for a player" },
  OwnPlayerGameStats: { defaultMessage: "Own Player Game Stats", description: "game stats for your own player" },
  TeamGameStats: { defaultMessage: "Team Game Stats", description: "stats from a game for a team" },
  TeamStats: { defaultMessage: "Team Stats", description: "stats from a game for a team" },
  GameStats: { defaultMessage: "Game Stats", description: "stats for a game" },
  BoxScore: { defaultMessage: "Box Score", description: "a summarry of the main stats for a game" },
  Defender: { defaultMessage: "Defender", description: "position for a soccer player" },
  Midfielder: { defaultMessage: "Midfielder", description: "position for a soccer player" },
  Forward: { defaultMessage: "Forward", description: "position for a soccer player" },
  Goalkeeper: { defaultMessage: "Goalkeeper", description: "position for a soccer player" },
  Defenders: { defaultMessage: "Defenders", description: "position for a soccer player" },
  Midfielders: { defaultMessage: "Midfielders", description: "position for a soccer player" },
  Forwards: { defaultMessage: "Forwards", description: "position for a soccer player" },
  Goalkeepers: { defaultMessage: "Goalkeepers", description: "position for a soccer player" },
  Caught: { defaultMessage: "Caught", description: "as in the goalkeeper caught the ball" },
  Blocked: { defaultMessage: "Blocked", description: "as in the goalkeeper blocked the ball" },
  SavedStat: { defaultMessage: "Saved", description: "as in the goalkeeper saved the ball" },
  Out: { defaultMessage: "Out", description: "As in the ball went out of bounds" },
  Coach: { defaultMessage: "Coach", description: "staff member role on a team" },
  Coaches: { defaultMessage: "Coaches", description: "staff member role on a team" },
  Admin: { defaultMessage: "Admin", description: "staff member role on a team" },
  Admins: { defaultMessage: "Admins", description: "staff member role on a team" },
  Staff: { defaultMessage: "Staff", description: "staff member role on a team" },
  HeadCoach: { defaultMessage: "Head Coach", description: "staff member role on a team" },
  HeadCoaches: { defaultMessage: "Head Coaches", description: "staff member role on a team, plural" },
  AllCoaches: { defaultMessage: "All Coaches", description: "all coaches including head coaches and assistant coaches" },
  AssistantCoach: { defaultMessage: "Assistant Coach", description: "staff member role on a team" },
  StaffMember: { defaultMessage: "Staff Member", description: "staff member role on a team" },
  TeamAdmin: { defaultMessage: "Team Admin", description: "staff member role on a team" },
  TeamAdmins: { defaultMessage: "Team Admins", description: "staff member role on a team, plural" },
  TeamStaff: { defaultMessage: "Team Staff", description: "staff member role on a team, plural, includes all staff members" },
  Athlete: { defaultMessage: "Athlete", description: "athlete on a team" },
  Player: { defaultMessage: "Player", description: "player on a team" },
  CoachesAndTeamAdmins: { defaultMessage: "Coaches and Team Admins", description: "Includes coaches and teams admins" },
  ClubAdmins: { defaultMessage: "Club Admins", description: "The administrators of the club (or organization)" },
  EntireClub: {
    defaultMessage: "Entire Club",
    description: "stating the a club (or organization) event includes all of the members of the club"
  },
  Players: { defaultMessage: "Players", description: "players on a team" },
  OppPlayer: { defaultMessage: "Opp. Player", description: "short for opposing player" },
  ParentGuardian: { defaultMessage: "Parent/Guardian", description: "non-staff role for someone on a team" },
  Squad: {
    defaultMessage: "Squad",
    description: "a high school team usually has 2 or 3 squads that are different teams within the high school"
  },
  Squads: {
    defaultMessage: "Squads",
    description: "a high school team usually has 2 or 3 squads that are different teams within the high school"
  },
  AllSquads: {
    defaultMessage: "All Squads",
    description: "a high school team usually has 2 or 3 squads that are different teams within the high school"
  },
  Add: {
    defaultMessage: "Add",
    description: "add something, not mathematically, but add something new or create something. Must be less than 6 characters."
  },
  AddDate: {
    defaultMessage: "Add Date",
    description: "add a date"
  },
  ExpirationDate: {
    defaultMessage: "Expiration Date",
    description: "date that something expires, or a guest player is removed from a team"
  },
  Exit: { defaultMessage: "Exit", description: "get out of a screen. Must be less than 6 characters." },
  Cross: { defaultMessage: "Cross", description: "a cross event in a game" },
  Crosses: { defaultMessage: "Crosses", description: "cross events in a game" },
  Scored: { defaultMessage: "Scored", description: "scored a goal or point" },
  Attempted: { defaultMessage: "Attempted", description: "attempted to score a goal or point" },
  Shot: { defaultMessage: "Shot", description: "a shot event in a game" },
  Shots: { defaultMessage: "Shots", description: "shot events in a game" },
  Goal: { defaultMessage: "Goal", description: "a goal event in a game" },
  Goals: { defaultMessage: "Goals", description: "goal events in a game" },
  GoalsFor: { defaultMessage: "Goals For", description: "goals that your team has scored" },
  GoalsAgainst: { defaultMessage: "Goals Against", description: "goals that the opponent has scored" },
  ShotsOnGoal: { defaultMessage: "Shots On Goal", description: "when a shot is on goal" },
  OnGoal: { defaultMessage: "On Goal", description: "when a shot is on goal" },
  onTarget: { defaultMessage: "On Target", description: "when a shot is on target" },
  offTarget: { defaultMessage: "Off Target", description: "when a shot is off target" },
  Timeline: { defaultMessage: "Timeline", description: "a list of events that occur in a game in order" },
  Lineup: { defaultMessage: "Lineup", description: "a formation of who is playing in the game" },
  Foul: { defaultMessage: "Foul", description: "a foul event in a game" },
  Fouls: { defaultMessage: "Fouls", description: "foul events in a game" },
  SuccessRate: {
    defaultMessage: "Success Rate",
    description: "term used in soccer to measure who successful a player is with keeping the ball"
  },
  Pass: { defaultMessage: "Pass", description: "a pass event in a game" },
  Passes: { defaultMessage: "Passes", description: "pass events in a game" },
  Assist: { defaultMessage: "Assist", description: "a Assist event in a game" },
  Assists: { defaultMessage: "Assists", description: "Assist events in a game" },
  Cards: { defaultMessage: "Cards", description: "yellow cards and red cards in a soccer game" },
  Handball: { defaultMessage: "Handball", description: "a handball event in a game" },
  Substitutions: { defaultMessage: "Substitutions", description: "when a player subs into the game for another player" },
  LongKick: { defaultMessage: "Long Kick", description: "a long kick event in a game" },
  GoalKick: { defaultMessage: "Goal Kick", description: "a goal kick event in a game" },
  CornerKick: { defaultMessage: "Corner Kick", description: "a corner kick event in a game" },
  CornerKicks: { defaultMessage: "Corner Kicks", description: "corner kick events in a game" },
  FreeKick: { defaultMessage: "Free Kick", description: "a Free kick event in a game" },
  FreeKicks: { defaultMessage: "Free Kicks", description: "Free kick events in a game" },
  PenaltyKick: { defaultMessage: "Penalty Kick", description: "a penalty kick event in a game" },
  Offside: { defaultMessage: "Offside", description: "an offside event in a game" },
  OwnGoal: { defaultMessage: "Own Goal", description: "an own goal event in a game where someone scores on their own goal" },
  OwnGoalShort: { defaultMessage: "OG", description: "Short for Own Goal" },
  Undo: { defaultMessage: "Undo", description: "undo an action. Must be less than 8 characters." },
  FieldPlayer: { defaultMessage: "Field Player", description: "a player who is on the field, not a goalkeeper" },
  Frame: { defaultMessage: "Frame", description: "frame or post is edges of the goal" },
  HeatMap: { defaultMessage: "Heat Map", description: "a map that shows where possession happens in a game on the field" },
  HeatMaps: { defaultMessage: "Heat Maps", description: "maps that show where possession happens in a game on the field" },
  ShotChart: { defaultMessage: "Shot Chart", description: "a chart that shows where shots happens in a game on the field" },
  ShotCharts: { defaultMessage: "Shot Charts", description: "charts that show where shots happens in a game on the field" },
  GoalCharts: { defaultMessage: "Goal Charts", description: "charts that show where goals happens in a game on the field" },
  Possession: { defaultMessage: "Possession", description: "a term in soccer for controlling or possessing the ball" },
  Corners: {
    defaultMessage: "Corners",
    description: "for corner kicks, a term in soccer for kicks from the corner of the field"
  },
  Turnover: { defaultMessage: "Turnover", description: "a term in soccer for losing the ball" },
  Turnovers: { defaultMessage: "Turnovers", description: "a term in soccer for losing the ball" },
  Recovery: { defaultMessage: "Recovery", description: "a term in soccer for stealing the ball" },
  Recoveries: { defaultMessage: "Recoveries", description: "a term in soccer for stealing the ball" },
  Clearance: { defaultMessage: "Clearance", description: "a clearance event in a game" },
  OurTeam: { defaultMessage: "Our Team", description: "the soccer team that the user belongs to" },
  MyTeam: { defaultMessage: "My Team", description: "the soccer team that the user belongs to" },
  MyTeams: { defaultMessage: "My Teams", description: "the soccer teams that the user belongs to" },
  MyPlayers: { defaultMessage: "My Players", description: "the players that I manage" },
  OpposingTeam: { defaultMessage: "Opposing Team", description: "the team that our team is competing against" },
  FirstHalf: { defaultMessage: "First Half", description: "the first half/period of the game" },
  SecondHalf: { defaultMessage: "Second Half", description: "the second half/period of the game" },
  Overtime: { defaultMessage: "Overtime", description: "if the game is tied at the end, it goes into overtime" },
  OT: { defaultMessage: "OT", description: "Short for overtime" },
  FirstOT: { defaultMessage: "First Overtime", description: "the first period of overtime in the game" },
  SecondOT: { defaultMessage: "Second Overtime", description: "the second period of overtime in the game" },
  RedCard: { defaultMessage: "Red Card", description: "a red card is shown to players in a soccer game who break the rules" },
  RedCards: { defaultMessage: "Red Cards", description: "red cards are shown to players in a soccer game who break the rules" },
  Beginner: { defaultMessage: "Beginner", description: "beginner is a mode for taking stats that is the easier mode" },
  Advanced: { defaultMessage: "Advanced", description: "advanced is a mode for taking stats that is the harder mode" },
  YellowCard: {
    defaultMessage: "Yellow Card",
    description: "a yellow card is shown to players in a soccer game who break the rules"
  },
  YellowCards: {
    defaultMessage: "Yellow Cards",
    description: "yellow cards are shown to players in a soccer game who break the rules"
  },
  Vs: {
    defaultMessage: "Vs",
    description: "short for versus or against, as in competing versus or against someone. Must be less than 6 characters."
  },
  Polls: { defaultMessage: "Polls", description: "polls or surveys" },
  All: { defaultMessage: "All", description: "everything" },
  Soccer: { defaultMessage: "soccer", description: "The sport Americans call 'Soccer'" },
  AmericanFootball: {
    defaultMessage: "football",
    description: "Otherwise known as gridiron, or the sport Americans call 'Football'"
  },
  Hockey: { defaultMessage: "hockey", description: "The sport Americans call 'Hockey'" },
  Basketball: { defaultMessage: "basketball", description: "The sport Americans call 'Basketball'" },
  Lacrosse: { defaultMessage: "lacrosse", description: "The sport Americans call 'Lacrosse'" },
  MountainBiking: { defaultMessage: "mountain biking", description: "The sport Americans call 'Mountain Biking'" },
  Baseball: { defaultMessage: "baseball", description: "The sport Americans call 'Baseball'" },
  Softball: { defaultMessage: "softball", description: "The sport Americans call 'Softball'" },
  Volleyball: { defaultMessage: "volleyball", description: "The sport Americans call 'Volleyball'" },
  WaterPolo: { defaultMessage: "water polo", description: "The sport Americans call 'Water Polo'" },
  Off: { defaultMessage: "Off", description: "The opposite of on, as in it is turned off" },
  Varsity: { defaultMessage: "Varsity", description: "The top squad for american on an american school team" },
  JuniorVarsity: { defaultMessage: "Junior Varsity", description: "The second squad for american on an american school team" },
  FreshmenSophomore: {
    defaultMessage: "Freshmen/Sophomore",
    description: "The youngest team for sophomores and freshmen on american school teams"
  },
  VarsityShort: {
    defaultMessage: "V",
    description: "The first letter or two for Varsity (the top squad for an american school team)"
  },
  JuniorVarsityShort: {
    defaultMessage: "JV",
    description: "The first letter or two for Junior Varsity (the second squad for american on an american school team)"
  },
  FreshmenSophomoreShort: {
    defaultMessage: "FS",
    description:
      "The first letter or two for Freshmen/Sophomore team (the youngest team for sophomores and freshmen on american school teams)"
  },
  Sophomore: { defaultMessage: "Sophomore", description: "The second youngest class in an american high school" },
  Freshmen: { defaultMessage: "Freshmen", description: "The youngest class in an american high school" },
  AddReaction: { defaultMessage: "Add Reaction", description: "For chat, add an emoji reaction" },
  Message: { defaultMessage: "Message", description: "message, as in a chat message" },
  Free: { defaultMessage: "Free", description: "No cost" },
  Pro: { defaultMessage: "Pro", description: "Short for professional" },
  Gold: { defaultMessage: "Gold", description: "gold color" },
  Silver: { defaultMessage: "Silver", description: "silver color" },
  Win: { defaultMessage: "Win", description: "Win as in win a game" },
  WinShort: { defaultMessage: "W", description: "First letter of win as in to win a game" },
  Loss: { defaultMessage: "Loss", description: "Loss as in losing a game" },
  LossShort: { defaultMessage: "L", description: "First letter of loss as in to lose a game" },
  Tie: { defaultMessage: "Tie", description: "Tie as in to tie a game" },
  TieShort: { defaultMessage: "T", description: "First letter of tie as in to tie a game" },
  Offense: { defaultMessage: "Offense", description: "Offense as in the opposite of defense in playing a sport" },
  Defense: { defaultMessage: "Defense", description: "Defense as in the opposite of offense in playing a sport" },
  Overall: { defaultMessage: "Overall", description: "Including both offense and defense" },
  OffenseMVP: { defaultMessage: "Offense MVP", description: "MVP for Offense as in the opposite of defense in playing a sport" },
  DefenseMVP: { defaultMessage: "Defense MVP", description: "MVP for Defense as in the opposite of offense in playing a sport" },
  OverallMVP: { defaultMessage: "Overall MVP", description: "Overall MVP Including both offense and defense" },
  Avg: { defaultMessage: "avg", description: "short for average" },
  Remove: { defaultMessage: "Remove", description: "Remove as in to remove someone or something from a list" },
  Manage: { defaultMessage: "Manage", description: "Manage as in add or edit" },
  Trial: { defaultMessage: "Trial", description: "temporary access to pro features" },
  Title: { defaultMessage: "Title", description: "the title of something" },
  Description: { defaultMessage: "Description", description: "a description of something" },
  Text: { defaultMessage: "Text", description: "text (like words and letters)" },
  Image: { defaultMessage: "Image", description: "image or photo" },
  SelectImage: { defaultMessage: "Select Image", description: "select image or photo" },
  Images: { defaultMessage: "Images", description: "images or photos" },
  Photo: { defaultMessage: "Photo", description: "image or photo" },
  Video: { defaultMessage: "Video", description: "a video" },
  Link: { defaultMessage: "Link", description: "like a URL" },
  Code: { defaultMessage: "Code", description: "like a code that is used to join a team" },
  Fundraising: { defaultMessage: "Fundraising", description: "the act of raising money" },
  Money: { defaultMessage: "Money", description: "money" },
  Prizes: { defaultMessage: "Prizes", description: "the prizes awarded for fundraising" },
  Prize: { defaultMessage: "Prize", description: "the prize awarded for fundraising" },
  Document: { defaultMessage: "Document", description: "like a PDF" },
  Monday: { defaultMessage: "Monday", description: "day of the week" },
  Tuesday: { defaultMessage: "Tuesday", description: "day of the week" },
  Wednesday: { defaultMessage: "Wednesday", description: "day of the week" },
  Thursday: { defaultMessage: "Thursday", description: "day of the week" },
  Friday: { defaultMessage: "Friday", description: "day of the week" },
  Saturday: { defaultMessage: "Saturday", description: "day of the week" },
  Sunday: { defaultMessage: "Sunday", description: "day of the week" },
  Season: { defaultMessage: "Season", description: "as in a soccer season, a period of time that a sport covers" },
  Seasons: { defaultMessage: "Seasons", description: "as in soccer seasons, a period of time that a sport covers" },
  LiveGameMode: {
    defaultMessage: "Live Game Mode",
    description: "a mode that happens during a game and shows all events that happen during a game in live time"
  },
  Call: { defaultMessage: "Call", description: "verb like to call on a phone" },
  Contact: { defaultMessage: "Contact", description: "verb like to contact someone via phone or email" },
  Home: {
    defaultMessage: "Home",
    description: "as in home team. The team who is hosting the game"
  },
  Away: {
    defaultMessage: "Away",
    description: "as in the away team. The team that is visiting and not hosting"
  },
  GoalFundraising: {
    defaultMessage: "Goal",
    description:
      "as in something that someone is striving to achieve. For example, a player has a goal to raise $500 in a fundraiser"
  },
  AmountRaised: { defaultMessage: "Amount Raised", description: "For example, the amount of money raised in a fundraiser" },
  Amount: { defaultMessage: "Amount", description: "For example, the amount of money raised in a fundraiser" },
  Balance: { defaultMessage: "Balance", description: "For example, the remaining balance of an invoice" },
  AmountOff: { defaultMessage: "Amount Off", description: "Used for coupons to specify the aount of money to discount" },
  PercentOff: { defaultMessage: "Percent Off", description: "Used for coupons to specify the percent of money to discount" },
  Percent: { defaultMessage: "Percent" },
  RecentDonations: { defaultMessage: "Recent Donations", description: "recent donations in a fundraiser" },
  Referrals: {
    defaultMessage: "Referrals",
    description: "People who are being referred as possibly being interested in fundraising"
  },
  AddReferrals: {
    defaultMessage: "Add Referrals",
    description: "People who are being referred as possibly being interested in fundraising"
  },
  AllDonations: { defaultMessage: "All Donations", description: "all donations in a fundraiser" },
  ViewAllDonations: { defaultMessage: "View All Donations", description: "view all donations in a fundraiser" },
  ShareFundraiser: { defaultMessage: "Share Fundraiser", description: "share a link to a fundraiser" },
  ChecksPayableInfo: { defaultMessage: "Checks Payable Info", description: "info for where to send checks to" },
  ChecksPayableTo: { defaultMessage: "Make Checks Payable To", description: "info for where to send checks to" },
  TeamSchoolName: { defaultMessage: "Team/School Name", description: "the name of the team or school" },
  Views: { defaultMessage: "Views", description: "number of views, like how many times a page has been viewed" },
  Ended: { defaultMessage: "Ended", description: "as in, the fundraiser has ended" },
  Tools: { defaultMessage: "Tools", description: "A group of resources that help accomplish things" },
  TeamTags: { defaultMessage: "Team Tags", description: "Tags that classify a team" },
  TeamTag: { defaultMessage: "Team Tag", description: "A tag that classifies a team" },
  TeamSummary: { defaultMessage: "Team Summary", description: "Summary for all teams" },
  MoneySettings: { defaultMessage: "Money Settings" },
  Tag: { defaultMessage: "Tag", description: "Used to label a team" },
  AddTeamTag: { defaultMessage: "Add Team Tag" },
  Tryouts: {
    defaultMessage: "Tryouts",
    description: "when players try to make a team, they all go to an event and the coach chooses the best players."
  },
  Camps: {
    defaultMessage: "Camps",
    description: "A common soccer event where players pay to attend an event where they are trained across multiple days."
  },
  Registration: {
    defaultMessage: "Registration",
    description: "when players try to register for a team"
  },
  Registrations: {
    defaultMessage: "Registrations",
    description: "as in registrations for a team"
  },
  Payment: {
    defaultMessage: "Payment",
    description: "As in a payment details for a registration package"
  },
  SomethingWentWrong: {
    defaultMessage: "Something went wrong. Please try again. If the issue persists, contact us at support@olliesports.com"
  },
  English: { defaultMessage: "English", description: "English language" },
  Spanish: { defaultMessage: "Spanish", description: "Spanish language" },
  Language: { defaultMessage: "Language" },
  Miscellaneous: { defaultMessage: "Miscellaneous" },
  TryoutSettings: {
    defaultMessage: "Tryout Settings",
    description: "Settings for tryouts which are when players compete for a spot on the team"
  },
  Expiration: { defaultMessage: "Expiration" },
  Alternate: {
    defaultMessage: "Alternate",
    description:
      "For tryouts, an alternate is someone who is not offered a spot on the team yet but might be offered a spot soon if some people reject spots on the team"
  },
  Accept: { defaultMessage: "Accept", description: "Accept as in to accept an offer to join a team" },
  Accepted: { defaultMessage: "Accepted", description: "Accepted as in to accepted an offer to join a team" },
  Expired: { defaultMessage: "Expired", description: "Expired as in an offer to join a team expired" },
  Decline: { defaultMessage: "Decline", description: "Decline as in to decline an offer to join a team" },
  Declined: { defaultMessage: "Declined", description: "Declined as in to declined an offer to join a team" },
  Status: { defaultMessage: "Status", description: "Status as in the status of a tryout offer" },
  ID: { defaultMessage: "ID", description: "Short for identifier" },
  Actions: { defaultMessage: "Actions", description: "Actions as in the actions that a user can carry out" },
  UseLimit: {
    defaultMessage: "Use Limit",
    description: "As in the number of times that a coupon code can be used"
  },
  TimesUsed: {
    defaultMessage: "Times Used",
    description: "As in the number of times that a coupon code has been used"
  },
  Discount: { defaultMessage: "Discount", description: "The discount of a coupon." },
  DiscountType: { defaultMessage: "Discount Type", description: "The discount type of a coupon." },
  UseType: { defaultMessage: "Use Type", description: "The use type of a coupon." },
  CreateTeamTag: { defaultMessage: "Create Team Tag", description: "Title for creating a team tag" },
  CreateCoupon: { defaultMessage: "Create Coupon", description: "Title for creating a coupon" },
  CreatePaymentPlan: { defaultMessage: "Create Payment Plan", description: "Title for creating a payment plan" },
  EditCoupon: { defaultMessage: "Edit Coupon", description: "Title for editing a coupon" },
  DiscountPercent: { defaultMessage: "Discount Percent", description: "The percent to discount" },
  DiscountAmount: { defaultMessage: "Discount Amount", description: "The amount to discount" },
  CouponType: { defaultMessage: "Coupon Type", description: "The type of coupon" },
  SelectDotDotDot: { defaultMessage: "Select...", description: "Telling the user to select an option" },
  ResetFilters: { defaultMessage: "Reset Filters", description: "Text for a button that resets filters" },
  Search: { defaultMessage: "Search", description: "Search for the desired entity" },
  PaymentSchedule: { defaultMessage: "Payment Schedule", description: "The schedule for payments" },
  NumPayments: { defaultMessage: "# Payments", description: "The number of payments in a payment plan" },
  Period: { defaultMessage: "Period", description: "As in monthly or quarterly" },
  PaymentPlanEvenlySplit: {
    defaultMessage: "Evenly Split",
    description: "Describing a payment plan where the payments are split evenly"
  },
  PaymentPlanCustomPercent: {
    defaultMessage: "Custom Percents",
    description: "Describing a payment plan where the payments are split based on customized percents"
  },
  FixedDates: {
    defaultMessage: "Fixed Dates",
    description: "Dates that are fixed"
  },
  PaymentPlanNumberOfDaysUntilLateFee: {
    defaultMessage: "Days Until Late",
    description: "Describing the number of days until a payment is considered late"
  },
  LateFee: {
    defaultMessage: "Late Fee",
    description: "Fee associated with a late payment"
  },
  PaymentPlanUserDueDateOverride: {
    defaultMessage: "Allow Payment Date Override",
    description: "Header for a setting that allows users to overried the due date of a payment"
  },
  PaymentPlanUserDueDateOverrideSuperShort: {
    defaultMessage: "Allow Override",
    description: "Header for a setting that allows users to overried the due date of a payment"
  },
  PaymentPlanUserDueDateOverrideSubtitle: {
    defaultMessage: "Allow users to manually override the date of a payment up until the date the payment becomes late?",
    description: "Details for a setting that allows users to overried the due date of a payment"
  },
  ReferenceImage: {
    defaultMessage: "Reference Image",
    description: "An image that is displayed as a reference"
  },
  PaymentPlan: {
    defaultMessage: "Payment Plan",
    description: "Payment plan used to pay for fees"
  },
  PaymentPlans: {
    defaultMessage: "Payment Plans",
    description: "Payment plans used to pay for fees"
  },
  PaymentPlanInstallment: {
    defaultMessage: "Payment Plan Installment",
    description: "An installment of a payment plan"
  },
  Priority: {
    defaultMessage: "Priority",
    description: "Priority"
  },
  Unassigned: {
    defaultMessage: "Unassigned",
    description: "As in when a team does not have an assigned registration package"
  },
  Assigned: {
    defaultMessage: "Assigned",
    description: "As in when a team does has an assigned registration package"
  },
  NoPackageRequired: {
    defaultMessage: "No Package Required",
    description: "No registration package is required"
  },
  Uncertain: {
    defaultMessage: "Uncertain",
    description: "As in when the registration package for a team is uncertain"
  },
  RegistrationPackage: {
    defaultMessage: "Registration Package",
    description: "A package of fees"
  },
  RegistrationPackages: {
    defaultMessage: "Registration Packages",
    description: "Package of fees"
  },
  WebsiteURL: {
    defaultMessage: "Website URL"
  },
  Permissions: {
    defaultMessage: "Permissions",
    description: "The permissions that a person on a team has"
  },
  Invoice: {
    defaultMessage: "Invoice",
    description: "Invoice as in an invoice that someone has to pay"
  },
  Invoices: {
    defaultMessage: "Invoices",
    description: "Invoices as in an invoice that someone has to pay"
  },
  Refresh: {
    defaultMessage: "Refresh",
    description: "Refresh something"
  },
  PayInFull: {
    defaultMessage: "Pay In Full",
    description: "Paying an invoice in 1 single payment"
  },
  Monthly: {
    defaultMessage: "Monthly",
    description: "Once a month"
  },
  Quarterly: {
    defaultMessage: "Quarterly",
    description: "Once a quarter"
  },
  Fees: {
    defaultMessage: "Fees",
    description: "Fees applied to a payment"
  },
  OtherFees: {
    defaultMessage: "Other Fees",
    description: "Other fees for a payment"
  },
  Subtotal: {
    defaultMessage: "Subtotal",
    description: "Subtotal of a payment"
  },
  Refunds: { defaultMessage: "Refunds", description: "Noun, as in refunds for payments made " },
  Alerts: { defaultMessage: "Alerts", description: "Alerts" },
  Members: { defaultMessage: "Members", description: "As in the members of a club" },
  Question: { defaultMessage: "Question", description: "Noun, as in a registration question" },
  Answer: { defaultMessage: "Answer", description: "Noun, as in an Answer to a question" },
  Coupons: { defaultMessage: "Coupons", description: "As in coupons for a discount" },
  ViewPreview: { defaultMessage: "View Preview", description: "View Preview" },
  HidePreview: { defaultMessage: "Hide Preview", description: "Hide Preview" },
  CreditDebitCard: { defaultMessage: "Credit/Debit Card", description: "Payment Methods" },
  CreditDebitCardShort: { defaultMessage: "Card", description: "Short way to say credit or debit card" },
  BankAccount: { defaultMessage: "Bank Account", description: "Payment Method" },
  AccountNumber: { defaultMessage: "Account Number", description: "Account Number for a bank account" },
  RoutingNumber: { defaultMessage: "Routing Number", description: "Routing Number for a bank account" },
  PaymentMethod: { defaultMessage: "Payment Method", description: "A method to use for payments" },
  PackageShort: { defaultMessage: "Package", description: "Short for registration package" },
  Label: { defaultMessage: "Label", description: "Noun, as in a label for something." },
  Reports: { defaultMessage: "Reports", description: "Noun, as in reports for a payments and invoices." }
});

export type CommonMessagesKeys = keyof typeof commonMessages;
