import { Simplify } from "type-fest";
import { TEAM_SPORT, TEAM_SPORT_TYPE } from "../../constants";
import { TypeEqualsType } from "../../internal-utils/typescript-utils";
import { SKE_americanFootballObj, SKE_americanFootball } from "./sports/american-football.schema";
import { SKE_baseballObj, SKE_baseball } from "./sports/baseball.schema";
import { SKE_basketballObj, SKE_basketball } from "./sports/basketball.schema";
import { SKE_otherObj, SKE_other } from "./sports/other.schema";
import { SKE_hockeyObj, SKE_hockey } from "./sports/hockey.schema";
import { SKE_lacrosseObj, SKE_lacrosse } from "./sports/lacrosse.schema";
import { SKE_soccerObj, SKE_soccer } from "./sports/soccer.schema";
import { SKE_volleyballObj, SKE_volleyball } from "./sports/volleyball.schema";
import { SKE_waterPoloObj, SKE_waterPolo } from "./sports/water-polo.schema";
import { SKE_mountainBiking, SKE_mountainBikingObj } from "./sports/mountain-biking";

export type ScoreKeepingEvent =
  | SKE_americanFootball
  | SKE_baseball
  | SKE_basketball
  | SKE_other
  | SKE_hockey
  | SKE_lacrosse
  | SKE_soccer
  | SKE_volleyball
  | SKE_waterPolo
  | SKE_mountainBiking;

export const ScoreKeepingEventsObjBySport = {
  [TEAM_SPORT.americanFootball]: SKE_americanFootballObj,
  [TEAM_SPORT.baseball]: SKE_baseballObj,
  [TEAM_SPORT.softball]: SKE_baseballObj,
  [TEAM_SPORT.basketball]: SKE_basketballObj,
  [TEAM_SPORT.other]: SKE_otherObj,
  [TEAM_SPORT.hockey]: SKE_hockeyObj,
  [TEAM_SPORT.lacrosse]: SKE_lacrosseObj,
  [TEAM_SPORT.soccer]: SKE_soccerObj,
  [TEAM_SPORT.volleyball]: SKE_volleyballObj,
  [TEAM_SPORT.waterPolo]: SKE_waterPoloObj,
  [TEAM_SPORT.mountainBiking]: SKE_mountainBikingObj
};

const assertAllSportsAccountedFor: TypeEqualsType<keyof typeof ScoreKeepingEventsObjBySport, TEAM_SPORT> = true;
