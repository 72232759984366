import {
  TeamFeatures,
  Team,
  Team__Account,
  AccountId,
  Account,
  PlayerId,
  Conversation,
  CONVERSATION_TYPES,
  TEAM_CONVERSATION_TYPES,
  TEAM_PERMISSIONS,
  Team__StaffTypes,
  PrettyPlayerBundle,
  TEAM_TYPES,
  Org,
  TEAM_ROLES,
  TEAM_SPORT,
  CALENDAR_ENTRY_TYPES
} from "@ollie-sports/models";
import _ from "lodash";
import { hasPermissionOnTeam } from ".";
import { fetchAccountIdsOnSquad } from "../internal-utils/team-utils";
import { ObjectKeys } from "../utils";
import { isSquadConversation, extactSquadKey, extactSquadSubset } from "./conversation.compute";
import { translate } from "@ollie-sports/i18n";

export function teamHasEnabledFeature(t: Team, f: TeamFeatures): boolean {
  return !!t.enabledFeatures?.[f];
}

export function teamExtractAccountIdsByType(team: Team, type: "staff" | "athlete" | "guardian"): AccountId[] {
  const result: AccountId[] = [];
  for (let accountId in team.accounts) {
    if ((team.accounts[accountId] as any)?.roles[type]) {
      result.push(accountId);
    }
  }
  return result;
}

export function teamExtractSquadKeys(squads: Partial<Team["squads"]>) {
  return squads
    ? ObjectKeys(squads)
        .filter(a => (a as any) !== "mapping")
        .sort()
    : [];
}

export function teamExtractStaffAccountIdsByTitle(p: { team: Team; staffTitle: Team__Account["staffTitle"] }): AccountId[] {
  const result: AccountId[] = [];
  for (let accountId in p.team.accounts) {
    if ((p.team.accounts[accountId] as any).staffTitle === p.staffTitle) {
      result.push(accountId);
    }
  }
  return result;
}

export function teamExtractPlayerIdsOnSquad(team: Team, squad: "a" | "b" | "c"): string[] {
  const mapping = team.squadsPlayerMapping || {};
  return Object.keys(mapping)
    .filter(pid => mapping[pid][squad])
    .filter(id => !!team.derived.activePlayerIds[id]);
}

export function teamExtractStaffIdsOnSquad(team: Team, squad: "a" | "b" | "c"): string[] {
  let staffIdsOnSquad: string[] = [];

  let staffAccountIds = Object.keys(team.accounts).reduce((acc: string[], accountId) => {
    if (team.accounts[accountId]?.roles.staff) {
      acc.push(accountId);
    }
    return acc;
  }, []);

  staffAccountIds.map(accountId => {
    if (squad === "a") {
      if (team?.accounts[accountId]?.additionalPermissions?.squad_a_staff) {
        staffIdsOnSquad.push(accountId);
      }
    } else if (squad === "b") {
      if (team?.accounts[accountId]?.additionalPermissions?.squad_b_staff) {
        staffIdsOnSquad.push(accountId);
      }
    } else if (squad === "c") {
      if (team?.accounts[accountId]?.additionalPermissions?.squad_c_staff) {
        staffIdsOnSquad.push(accountId);
      }
    }
  });

  return staffIdsOnSquad;
}

export function atLeastOneTeamHasFeature(p: { teams: Team[]; f: TeamFeatures }): boolean {
  for (let i = 0; i < p.teams.length; i++) {
    if (!!p.teams[i].enabledFeatures?.[p.f]) {
      return true;
    }
  }

  return false;
}

export function shouldShowTeamOnboarding(p: { team: Team; accountId: AccountId }) {
  return !p.team.hasOnboarded && p.team.accounts[p.accountId]?.additionalPermissions?.manageRolesAndPermissions;
}

export function getRelevantSquads(p: { accountId: AccountId; managedPlayerIds: PlayerId[]; team: Team }) {
  const squads: ("a" | "b" | "c")[] = [];
  if (p.team.squads) {
    (["a", "b", "c"] as ("a" | "b" | "c")[]).forEach(squad => {
      isPartOfSquad({ accountId: p.accountId, managedPlayerIds: p.managedPlayerIds, team: p.team, squad: squad }) &&
        squads.push(squad);
    });
  }

  return squads;
}

export function isPartOfSquad(p: { accountId?: AccountId; managedPlayerIds?: PlayerId[]; team: Team; squad: "a" | "b" | "c" }) {
  let hasSquadPermission = false;
  let managesPlayerOnSquad = false;
  if (p.accountId) {
    const currentTeamAccount = p.team.accounts[p.accountId];
    if (currentTeamAccount) {
      hasSquadPermission = !!(p.squad === "a"
        ? currentTeamAccount.additionalPermissions?.squad_a_staff
        : p.squad === "b"
        ? currentTeamAccount.additionalPermissions?.squad_b_staff
        : currentTeamAccount.additionalPermissions?.squad_c_staff);
    }
  }
  if (p.managedPlayerIds) {
    p.managedPlayerIds.forEach(playerId => {
      if (
        (p.squad === "a" && p.team.squadsPlayerMapping?.[playerId]?.a) ||
        (p.squad === "b" && p.team.squadsPlayerMapping?.[playerId]?.b) ||
        (p.squad === "c" && p.team.squadsPlayerMapping?.[playerId]?.c)
      ) {
        managesPlayerOnSquad = true;
      }
    });
  }

  return hasSquadPermission || managesPlayerOnSquad;
}

const rolesBySeniority = [
  Team__StaffTypes.headCoach,
  Team__StaffTypes.assistantCoach,
  Team__StaffTypes.teamAdmin,
  Team__StaffTypes.staffMember
];

export function extractHighestRankingStaff(team: Team): { accountId: string; role: Team__StaffTypes } | null {
  const topRole = rolesBySeniority.find(
    role => Object.keys(team.accounts).find(accId => team.accounts[accId]?.staffTitle === role) || ""
  );

  const topStaffId = Object.keys(team.accounts).find(accId => team.accounts[accId]?.staffTitle === topRole);

  if (topStaffId && topRole) {
    return {
      accountId: topStaffId,
      role: topRole
    };
  }

  return null;
}

type HighestStaffRecord = Record<string, { account: Account; role: Team__StaffTypes } | undefined>;

export function computeTeamIdToHighestRankingStaff(p: {
  teams: Team[];
  getAccounts: (accountIds: string[]) => Account[];
}): HighestStaffRecord;
export function computeTeamIdToHighestRankingStaff(p: {
  teams: Team[];
  getAccounts: (accountIds: string[]) => Promise<Account[]>;
}): Promise<HighestStaffRecord>;
export function computeTeamIdToHighestRankingStaff(p: {
  teams: Team[];
  getAccounts: (accountIds: string[]) => Promise<Account[]> | Account[];
}): HighestStaffRecord | Promise<HighestStaffRecord> {
  const topStaffRawInfoByTeamId = p.teams.reduce((acc, t) => {
    const topStaff = extractHighestRankingStaff(t);
    if (topStaff) {
      acc[t.id] = {
        accountId: topStaff.accountId,
        role: topStaff.role
      };
    }

    return acc;
  }, {} as Record<string, { accountId: string; role: Team__StaffTypes }>);

  const topStaffIds = _(Object.values(topStaffRawInfoByTeamId))
    .map(a => a.accountId)
    .filter(a => !!a)
    .uniq()
    .valueOf();

  const allTeamsTopStaffReturn = p.getAccounts(topStaffIds);

  function createTopStaffInfoByTeamId(accounts: Account[]) {
    return _.mapValues(topStaffRawInfoByTeamId, info => {
      const topStaffAccount = accounts.find(account => account.id === info.accountId);

      if (!topStaffAccount) {
        return undefined;
      }

      return {
        account: topStaffAccount,
        role: info.role
      };
    });
  }

  if ("then" in allTeamsTopStaffReturn) {
    return allTeamsTopStaffReturn.then(accounts => {
      return createTopStaffInfoByTeamId(accounts);
    });
  } else {
    return createTopStaffInfoByTeamId(allTeamsTopStaffReturn);
  }
}

export function partitionAccountIdsByRole(team: Team): Record<"staff" | "guardians" | "athletes", string[]> {
  const byRole = {
    staff: [] as string[],
    guardians: [] as string[],
    athletes: [] as string[]
  };

  Object.keys(team.accounts).forEach(accId => {
    const roles = team.accounts[accId]?.roles || {};
    const role = roles.staff ? "staff" : roles.guardian ? "guardians" : "athletes";
    byRole[role].push(accId);
  });

  //Have highest ranking staff first
  byRole.staff = _.orderBy(byRole.staff, accId => {
    const type = team.accounts[accId]?.staffTitle || Team__StaffTypes.staffMember;
    return rolesBySeniority.indexOf(type);
  });

  return byRole;
}

export function canChatWithAccount(p: {
  selfAccountId: AccountId;
  otherAccountId: AccountId;
  team: Team;
  orgs: Org[];
  managedPlayerBundles: PrettyPlayerBundle[];
  userTeams: Team[];
}) {
  if (p.team.teamType === TEAM_TYPES.college) {
    return true;
  }

  const isSelfOrgAdmin = !!p.team.orgId && !!p.orgs.find(o => o.id === p.team.orgId && !!o.accounts[p.selfAccountId]);
  const isOtherOrgAdmin = !!p.team.orgId && !!p.orgs.find(o => o.id === p.team.orgId && !!o.accounts[p.otherAccountId]);

  if (isSelfOrgAdmin || isOtherOrgAdmin) {
    return true;
  }

  const doAccountsShareAnyPlayerBundles = p.managedPlayerBundles.find(pb =>
    ObjectKeys(pb.playerBundle.managingAccounts ?? 0).find(aid => aid === p.otherAccountId)
  );

  // Always let them chat if they share any player bundles
  if (doAccountsShareAnyPlayerBundles) {
    return true;
  }

  const isSelfAccountOnTeam = !!p.team.accounts[p.selfAccountId];
  const isSelfAccountAthleteOnTeam = p.team.accounts[p.selfAccountId]?.roles.athlete;
  const isSelfAccountGuardianOnTeam = p.team.accounts[p.selfAccountId]?.roles.guardian;
  const isSelfAccountStaffOnTeam = p.team.accounts[p.selfAccountId]?.roles.staff;

  const isSelfStaffOnAnyTeamInClub = p.team.orgId
    ? !!p.userTeams.find(t => t.orgId === p.team.orgId && !!t.accounts[p.selfAccountId]?.roles.staff)
    : false;
  const isSelfGuardianOnAnyTeamInClub = p.team.orgId
    ? !!p.userTeams.find(t => t.orgId === p.team.orgId && !!t.accounts[p.selfAccountId]?.roles.guardian)
    : false;
  const isSelfAthleteOnAnyTeamInClub = p.team.orgId
    ? !!p.userTeams.find(t => t.orgId === p.team.orgId && !!t.accounts[p.selfAccountId]?.roles.athlete)
    : false;

  const isOtherAccountAthlete = !!p.team.accounts[p.otherAccountId]?.roles.athlete;
  const isOtherAccountGuardian = !!p.team.accounts[p.otherAccountId]?.roles.guardian;
  const isOtherAccountStaff = !!p.team.accounts[p.otherAccountId]?.roles.staff;

  if (isSelfAccountOnTeam) {
    // Team Connection
    if (isSelfAccountStaffOnTeam) {
      return true;
    } else if (isSelfAccountGuardianOnTeam) {
      // Guardians can't chat with athlete
      return !isOtherAccountAthlete;
    } else {
      // Athletes can't chat with guardians
      return !isOtherAccountGuardian;
    }
  } else {
    // Club connection
    if (isOtherAccountStaff) {
      // Guardians and Staff can chat with staff from other org teams
      return isSelfGuardianOnAnyTeamInClub || isSelfStaffOnAnyTeamInClub;
    } else if (isOtherAccountGuardian) {
      // Athletes can't chat with guardians
      return !isSelfAthleteOnAnyTeamInClub;
    } else {
      // Only athletes can chat with other athletes
      return isSelfAthleteOnAnyTeamInClub;
    }
  }
}

export function canViewContactInfo(p: {
  selfAccountId: AccountId;
  otherAccountId: AccountId;
  team: Team;
  orgs: Org[];
  managedPlayerBundles: PrettyPlayerBundle[];
  userTeams: Team[];
}) {
  if (p.team.teamType === TEAM_TYPES.college) {
    return true;
  }

  if (p.otherAccountId === p.selfAccountId) {
    return true;
  }

  const doAccountsShareAnyPlayerBundles = p.managedPlayerBundles.find(pb =>
    ObjectKeys(pb.playerBundle.managingAccounts ?? 0).find(aid => aid === p.otherAccountId)
  );

  // Always let them view contact info if they share any player bundles
  if (doAccountsShareAnyPlayerBundles) {
    return true;
  }

  const isSelfAccountAthleteOnTeam = !!p.team.accounts[p.selfAccountId]?.roles.athlete;
  const isSelfGuardianOnTeam = !!p.team.accounts[p.selfAccountId]?.roles.guardian;
  const isSelfStaffOnTeam = !!p.team.accounts[p.selfAccountId]?.roles.staff;
  const isSelfOnTeam = isSelfAccountAthleteOnTeam || isSelfGuardianOnTeam || isSelfStaffOnTeam;

  const isOtherAccountAthleteOnTeam = !!p.team.accounts[p.otherAccountId]?.roles.athlete;
  const isOtherAccountGuardianOnTeam = !!p.team.accounts[p.otherAccountId]?.roles.guardian;
  const isOtherAccountStaffOnTeam = !!p.team.accounts[p.otherAccountId]?.roles.staff;
  const isOtherOnTeam = isOtherAccountAthleteOnTeam || isOtherAccountGuardianOnTeam || isOtherAccountStaffOnTeam;

  const org = p.team.orgId ? p.orgs.find(o => o.id === p.team.orgId) : undefined;
  const isSelfOrgAdmin = !!org?.accounts[p.selfAccountId]?.exists;
  const isOtherOrgAdmin = !!org?.accounts[p.otherAccountId]?.exists;

  if ((isSelfOrgAdmin && !isOtherAccountAthleteOnTeam) || (isOtherOrgAdmin && !isSelfAccountAthleteOnTeam)) {
    return true;
  }

  if (isSelfOnTeam && isOtherOnTeam && !(isSelfAccountAthleteOnTeam || isOtherAccountAthleteOnTeam)) {
    return true;
  }

  return false;
}

export function isCoachFactoringIntoAccountOptionalSquad(p: { selfAccountId: AccountId; team?: Team; squad?: "a" | "b" | "c" }) {
  const teamAccount = p.team?.accounts[p.selfAccountId];

  // If not a coach return false
  if (
    !teamAccount ||
    !(teamAccount.staffTitle === Team__StaffTypes.assistantCoach || teamAccount.staffTitle === Team__StaffTypes.headCoach)
  ) {
    return false;
  }

  if (p.squad) {
    switch (p.squad) {
      case "a":
        return !!teamAccount.additionalPermissions?.squad_a_staff;
      case "b":
        return !!teamAccount.additionalPermissions?.squad_b_staff;
      case "c":
        return !!teamAccount.additionalPermissions?.squad_c_staff;
    }
  }

  return true;
}

export function hasAnyTeamPermission(p: { teamAccount: Team__Account }) {
  return (
    !!p.teamAccount.additionalPermissions?.manageEvents ||
    !!p.teamAccount.additionalPermissions?.manageRolesAndPermissions ||
    !!p.teamAccount.additionalPermissions?.recordStats ||
    !!p.teamAccount.additionalPermissions?.manageRoster ||
    !!p.teamAccount.additionalPermissions?.viewIndividualStats
  );
}

export function getTeamRoleAndStaffComboArrayForAccountId(p: {
  team: Team;
  accountId: AccountId;
}): (Team__StaffTypes | TEAM_ROLES)[] {
  const teamAccount = p.team.accounts[p.accountId];

  if (!teamAccount) {
    return [];
  }

  const returnVal: (Team__StaffTypes | TEAM_ROLES)[] = [];

  if (teamAccount.roles.athlete) {
    returnVal.push(TEAM_ROLES.athlete);
  }
  if (teamAccount.roles.guardian) {
    returnVal.push(TEAM_ROLES.guardian);
  }
  if (teamAccount.roles.staff && teamAccount.staffTitle) {
    returnVal.push(teamAccount.staffTitle);
  }

  return returnVal;
}

export function getCalendarEntryTypeDisplayForTeamSport(p: {
  teamSport: TEAM_SPORT;
  serverLocale: string;
}): Record<CALENDAR_ENTRY_TYPES, string> {
  let gameString = translate.common(p.serverLocale).Game;
  let scrimmageString = translate.common(p.serverLocale).Scrimmage;
  let practiceString = translate.common(p.serverLocale).Practice;
  let otherString = translate({
    defaultMessage: "Other Event",
    description: "an event other than game, practice, or scrimmage",
    serverLocale: p.serverLocale
  });
  switch (p.teamSport) {
    case TEAM_SPORT.mountainBiking:
      gameString = translate.common(p.serverLocale).Race;
      break;
    case TEAM_SPORT.soccer:
    case TEAM_SPORT.americanFootball:
    case TEAM_SPORT.basketball:
    case TEAM_SPORT.baseball:
    case TEAM_SPORT.hockey:
    case TEAM_SPORT.softball:
    case TEAM_SPORT.lacrosse:
    case TEAM_SPORT.volleyball:
    case TEAM_SPORT.waterPolo:
    case TEAM_SPORT.other:
  }
  return { game: gameString, other: otherString, practice: practiceString, scrimmage: scrimmageString };
}

// i18n certified - complete
