import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import {
  Account,
  CalendarEntry,
  CalendarEntryOther,
  CALENDAR_ENTRY_TYPES,
  EVENT_REMINDER_NOTIFICATION_TYPES,
  EVENT_TYPE_REMINDER_INTERVAL_HOURS,
  LowPriorityNotificationDetailType,
  NotificationType,
  Org,
  PushNotificationSettingToRespect,
  TEAM_SPORT
} from "@ollie-sports/models";

import { getLocationURL, getVerboseLocationString } from "../../compute/calendarEntry.compute";

import express from "express";
import _ from "lodash";
import moment from "moment-timezone";
import { generateAndProcessNotifications, NotificationGeneratorInfo__LowPriority } from "../notification/notification.plumbing";
import { calendarEntry__server__getAccountIdsForOrgEvent } from "../calendarEntry";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import { prettyCalendarEntryStrings } from "../../compute";
import { isProduction } from "../../utils";

export async function eventReminders__server__sendForcedOrgEventReminders() {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();
  const { olliePipe, ollieFirestoreV2: h } = getUniversalHelpers();

  try {
    type ReminderTimes = 0 | 1 | 24 | 96;
    const times: ReminderTimes[] = [0, 1, 24, 96];

    const results = await Promise.all(
      times
        .map(hours => [
          moment().add(hours, "hour").startOf("minute").subtract(1, "minute").valueOf(), // N hours minus 1 minute.
          moment().add(hours, "hour").startOf("minute").add(4, "minute").valueOf(), // N hours plus 4 minutes
          hours //N hours
        ])
        .map(params => {
          return getAppPgPool().query(
            ` select c.item calendarentry,
                     o.item  org,
                     $3      hours
              from mirror_calendarentry c,
                  mirror_org o
              where c.item ->'orgEventOrgId' is not null
                and c.item->>'orgEventOrgId' = o.id
                and c.item->>'forceOrgEventReminders' = 'true'
                and c.c_start_date_truncated_ms >= $1
                and c.c_start_date_truncated_ms < $2`,
            params
          );
        })
    );

    type Info = { calendarEntry: CalendarEntryOther; org: Org; hours: ReminderTimes };
    const orgEventsInfo: Info[] = _(results)
      .map(a => a.rows)
      .flatten()
      .map(r => ({
        calendarEntry: r.calendarentry as CalendarEntryOther,
        org: r.org as Org,
        hours: Number(r.hours) as any
      }))
      .value();

    const accountIdsWithInfo: (Info & { accountId: string })[] = [];
    await Promise.all(
      orgEventsInfo.map(async a => {
        const accountIds = await calendarEntry__server__getAccountIdsForOrgEvent({ calendarEntry: a.calendarEntry });
        accountIds.forEach(accountId => {
          accountIdsWithInfo.push({ ...a, accountId });
        });
      })
    );

    const accountCache: Record<string, Account | null> = {};
    async function getAccountCached(accountId: string) {
      if (!accountCache[accountId]) {
        accountCache[accountId] = await h.Account.getDoc(accountId);
      }

      return accountCache[accountId];
    }

    await generateAndProcessNotifications({
      data: accountIdsWithInfo,
      generateFn: async ({ accountPrivate, data }) => {
        const serverLocale = accountPrivate.communicationLocale;

        if (!accountPrivate.settings?.notifications.calendarEvents || !serverLocale) {
          //Don't send if calendarEvent notifications are turned off
          return null;
        }

        const videoConferencingUrl = data.calendarEntry.videoConferencingUrl;

        const { shortDate, shortTimeWithoutTimezone, endOClock } = prettyCalendarEntryStrings(
          data.calendarEntry,
          serverLocale,
          TEAM_SPORT.other
        );

        const dateInMyZone = moment.tz(data.calendarEntry.startDateTime, data.calendarEntry.timezone);
        const startTime = dateFormatters.t_tt_a(dateInMyZone, serverLocale);
        const dateString = shortDate;
        const dayOfWeek = dateFormatters.dayOfWeek(dateInMyZone, serverLocale);
        const timeString = `${shortTimeWithoutTimezone}${endOClock ? ` - ${endOClock}` : ""}`;
        const expireAtMS = Date.now() + data.hours * 60 * 60 * 1000;
        const calendarEntryTitle = data.calendarEntry.title;

        const notificationBodyByHour: Record<ReminderTimes, string> = {
          0: [
            translate({ defaultMessage: "{calendarEntryTitle} happening now!", serverLocale }, { calendarEntryTitle }),
            videoConferencingUrl ? translate({ defaultMessage: "Tap notification to join", serverLocale }) : ""
          ]
            .filter(a => a)
            .join(" "),
          1: [
            translate({ defaultMessage: "{calendarEntryTitle} happening in 1 hour!", serverLocale }, { calendarEntryTitle }),
            videoConferencingUrl
              ? translate({ defaultMessage: "Join at {videoConferencingUrl}.", serverLocale }, { videoConferencingUrl })
              : data.calendarEntry.location?.venue
              ? translate({ defaultMessage: "Be there at {venue}.", serverLocale }, { venue: data.calendarEntry.location.venue })
              : ""
          ]
            .filter(a => a)
            .join(" "),
          24: translate(
            {
              defaultMessage: "Remember, {calendarEntryTitle} happening TOMORROW at {startTime}. View details in Ollie.",
              serverLocale
            },
            { calendarEntryTitle, startTime }
          ),
          96: translate(
            {
              defaultMessage: "Remember, {calendarEntryTitle} this {dayOfWeek} at {startTime}. View details in Ollie.",
              serverLocale
            },
            { calendarEntryTitle, dayOfWeek, startTime }
          )
        };

        const pushNotificationInfo = {
          title: data.calendarEntry.title,
          body: notificationBodyByHour[data.hours],
          routerPath:
            videoConferencingUrl && data.hours === 1 ? videoConferencingUrl : `main/events/event/${data.calendarEntry.id}`
        };

        const info: NotificationGeneratorInfo__LowPriority = {
          idempotencyKey: [
            "org-event-reminder",
            data.hours,
            data.calendarEntry.id,
            data.calendarEntry.startDateTime,
            data.calendarEntry.timezone
          ].join("|"),
          notificationType: NotificationType.lowPriorityNotification,
          type: "lowPriorityNotification",
          pushNotificationData: {
            pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
            lowPriorityNotificationDetailType: LowPriorityNotificationDetailType.eventReminder,
            ...pushNotificationInfo
          },
          lowPriorityNotificationDetail: {
            expireAtMS: expireAtMS,
            type: LowPriorityNotificationDetailType.eventReminder,
            ...pushNotificationInfo
          },
          realTimeNotification: {
            e: expireAtMS
          }
        };

        //Always send EMAIL org event reminders 1 hour and 4 days before
        if (data.hours === 1 || data.hours === 96) {
          //TODO: Verify the email hasn't already been sent using the idempotency key.
          const account = await getAccountCached(accountPrivate.id);

          if (account) {
            const locationString = data.calendarEntry.location
              ? getVerboseLocationString(data.calendarEntry.location, serverLocale) ?? ""
              : "";
            const locationURL = data.calendarEntry.location ? getLocationURL(data.calendarEntry.location) ?? "" : "";

            const subject = {
              1: translate({ serverLocale, defaultMessage: "{calendarEntryTitle} starting in 1 hour!" }, { calendarEntryTitle }),
              96: translate(
                { serverLocale, defaultMessage: "{calendarEntryTitle} - {dayOfWeek} at {startTime}" },
                { calendarEntryTitle, dayOfWeek, startTime }
              )
            }[data.hours];

            const introText = {
              1: translate(
                { serverLocale, defaultMessage: "{calendarEntryTitle} happening in 1 hour!" },
                {
                  calendarEntryTitle: `${data.org.name} - ${calendarEntryTitle}`
                }
              ),
              96: translate(
                { serverLocale, defaultMessage: "Just a reminder about the {calendarEntryTitle} happening in 4 days!" },
                { calendarEntryTitle: `${data.org.name} - ${calendarEntryTitle}` }
              )
            }[data.hours];

            const videoConferencingUrlIntro = {
              1: translate({ serverLocale, defaultMessage: "You can join the meeting at this link:" }),
              96: translate({ serverLocale, defaultMessage: "When the event starts in 4 days, please join at this link:" })
            }[data.hours];

            info.emailData = {
              type: "normal",
              email: account.email,
              templateId: "d-f7adf960edef49fab4928d26313d321d",
              hideWarnings: true,
              unsubscribeGroup: 19514,
              templateData: {
                subject,
                introText,
                eventTitle: calendarEntryTitle,
                dateString,
                timeString,
                locationString,
                locationURL,
                videoConferencingUrlIntro,
                videoConferencingUrl
              }
            };
          }
        }
        return info;
      }
    });

    return { success: true, numRemindersSent: orgEventsInfo.length };
  } catch (e) {
    await olliePipe.emitEvent({ type: "error-sending-org-event-reminders", payload: e }, { sendImmediate: true });
    return { success: false };
  }

  // SERVER_ONLY_TOGGLE
}

eventReminders__server__sendForcedOrgEventReminders.auth = async (r: express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called via cron jobs in production!");
  }
};

// i18n certified - complete
