import { Typography } from "@material-ui/core";
import _ from "lodash";
import {
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgPayment,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceCreditApplicationMethod,
  OrgPaymentType,
  PaymentMethodType
} from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import {
  COLORS,
  filterOrgPaymentInvoices,
  formatMoneyCentsToDollarCentPrettyString,
  getIndividualOrgInvoiceAmountDetails
} from "@ollie-sports/core";
import { useState } from "react";
import { Form, PrettyCoolSelectInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { StyledText } from "../../components/StyledText";
import { View } from "react-native-web";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { openErrorToast } from "../../utils/openErrorToast";

type PropsForParentInvoiceCredit = {
  orgInvoiceParent: OrgInvoiceParent;
  maxAmountCents: number;
  scheduledChildrenOrgInvoices: OrgInvoiceChild[];
  orgPayments: OrgPayment[];
  onComplete: () => Promise<void>;
};

export function openOrgPaymentInvoiceCreditAddModal(p: PropsForParentInvoiceCredit) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <OrgPaymentInvoiceCreditAddModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
          }}
        />
      )
    });
  });
}

function OrgPaymentInvoiceCreditAddModal(p: PropsForParentInvoiceCredit & { onRequestDismiss: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [amountCents, setAmountCents] = useState<number | undefined>();
  const [amountShadow, setAmountShadow] = useState("");
  const [note, setNote] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedApplicationMethod, setSelectedApplicationMethod] = useState<
    OrgPaymentInvoiceCreditApplicationMethod | undefined
  >();

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Add Credit" })}
            bottomButton={{
              title: translate.common.Apply,
              enabled: isFormValid,
              onPress: async () => {
                setIsLoading(true);
                try {
                  if (!amountCents || !note || (p.scheduledChildrenOrgInvoices.length > 1 && !selectedApplicationMethod)) {
                    throw new Error("");
                  }
                  await getBifrost().orgPayment__server__addOrgPaymentInvoiceCredits.fetchServer({
                    amountCents,
                    type: "all-outstanding",
                    note,
                    orgId: p.orgInvoiceParent.orgId,
                    parentOrgInvoiceId: p.orgInvoiceParent.id,
                    selfAccountId: getCurrentUserAccountId(),
                    applicationMethod: selectedApplicationMethod || OrgPaymentInvoiceCreditApplicationMethod.equal
                  });
                  await p.onComplete();
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem applying the credit. Please try again or contact support@olliesports.com"
                    })
                  );
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={() => {
              if (
                (amountCents || note) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <div>
              <View>
                <StyledText>
                  {translate({
                    defaultMessage: "You can apply credit up to the remaining amount on the invoice."
                  })}
                </StyledText>
                <View style={{ flexDirection: "row", marginTop: 16 }}>
                  <StyledText>{`${translate({
                    defaultMessage: "Remaining Amount"
                  })}:`}</StyledText>
                  <StyledText style={{ fontWeight: "bold" }}>{`${formatMoneyCentsToDollarCentPrettyString(
                    p.maxAmountCents
                  )}`}</StyledText>
                </View>
              </View>
              <PrettyCoolTextInputWithLabel
                label={translate({ defaultMessage: "Amount" })}
                isRequired
                isMoney
                onChange={newAmtRaw => {
                  const newAmt = newAmtRaw?.match(/\d+\.?\d?\d?/)?.[0];
                  setAmountShadow(newAmt || "");
                  setAmountCents(newAmt ? Number(newAmt) * 100 : undefined);
                }}
                validate={val => {
                  if (!val) {
                    return translate.common.IsRequired;
                  }
                  const valNumber = Number((Number(val) * 100).toFixed(2));
                  if (valNumber > p.maxAmountCents) {
                    return translate(
                      { defaultMessage: "The Amount cannot exceed {amount}." },
                      { amount: formatMoneyCentsToDollarCentPrettyString(p.maxAmountCents) }
                    );
                  }
                  return "";
                }}
                inputProps={{ type: "number", min: 0 }}
                value={amountShadow}
                style={{ marginTop: 16 }}
              />
              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={translate.common.Note}
                value={note}
                isRequired
                onChange={newVal => {
                  setNote(newVal ?? "");
                }}
              />
              {p.scheduledChildrenOrgInvoices.length > 1 ? (
                <PrettyCoolSelectInput
                  containerStyle={{ marginTop: 16 }}
                  label={translate({ defaultMessage: "How do you want to apply the credit?" })}
                  onChange={newVal => {
                    if (newVal) {
                      setSelectedApplicationMethod(newVal as OrgPaymentInvoiceCreditApplicationMethod);
                    } else {
                      setSelectedApplicationMethod(undefined);
                    }
                  }}
                  value={selectedApplicationMethod}
                  options={Object.keys(OrgPaymentInvoiceCreditApplicationMethod).map(method => {
                    return {
                      value: method,
                      label:
                        method === OrgPaymentInvoiceCreditApplicationMethod.beginning
                          ? translate({ defaultMessage: "Beginning: Apply to earliest payments first" })
                          : method === OrgPaymentInvoiceCreditApplicationMethod.end
                          ? translate({ defaultMessage: "End: Apply to last payments first" })
                          : translate({ defaultMessage: "Even: Divide evenly among all remaining payments" })
                    };
                  })}
                  isRequired
                />
              ) : null}

              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}

type PropsForScheduledPaymentInvoiceCredit = {
  orgInvoice: OrgInvoice;
  orgInvoiceParent: OrgInvoiceParent; //This may be the same as the orgInvoice
  maxAmountCents: number;
  initialAmountCents?: number;
  onComplete: () => Promise<void>;
};

export function openOrgPaymentInvoiceCreditAddToScheduledPaymentModal(p: PropsForScheduledPaymentInvoiceCredit) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <OrgPaymentInvoiceCreditAddToScheduledPaymentModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
          }}
        />
      )
    });
  });
}

function OrgPaymentInvoiceCreditAddToScheduledPaymentModal(
  p: PropsForScheduledPaymentInvoiceCredit & { onRequestDismiss: () => void }
) {
  const [amountCents, setAmountCents] = useState<number | undefined>(p.initialAmountCents);
  const [amountShadow, setAmountShadow] = useState(amountCents ? (amountCents / 100).toFixed(2).toString() : "");

  console.log(amountCents, amountShadow);
  const [note, setNote] = useState("");

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Add Credit" })}
            bottomButton={{
              title: translate.common.Apply,
              enabled: isFormValid,
              onPress: async () => {
                try {
                  if (!amountCents || !note) {
                    throw new Error("");
                  }

                  await getBifrost().orgPayment__server__addOrgPaymentInvoiceCredits.fetchServer({
                    amountCents,
                    type: "single",
                    note,
                    orgId: p.orgInvoice.orgId,
                    orgInvoiceId: p.orgInvoice.id,
                    parentOrgInvoiceId: p.orgInvoiceParent.id,
                    selfAccountId: getCurrentUserAccountId()
                  });
                  await p.onComplete();
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem applying the credit. Please try again or contact support@olliesports.com"
                    })
                  );
                }
              }
            }}
            onRequestDismiss={() => {
              if (
                (amountCents !== p.initialAmountCents || note) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <div>
              <View>
                <StyledText>
                  {translate({
                    defaultMessage: "You can apply credit up to the remaining amount on the scheduled payment."
                  })}
                </StyledText>
                <View style={{ flexDirection: "row", marginTop: 16 }}>
                  <StyledText>{`${translate({
                    defaultMessage: "Remaining Amount"
                  })}: `}</StyledText>
                  <StyledText style={{ fontWeight: "bold" }}>{`${formatMoneyCentsToDollarCentPrettyString(
                    p.maxAmountCents
                  )}`}</StyledText>
                </View>
              </View>
              <PrettyCoolTextInputWithLabel
                label={translate({ defaultMessage: "Amount" })}
                isRequired
                isMoney
                onChange={newAmtRaw => {
                  const newAmt = newAmtRaw?.match(/\d+\.?\d?\d?/)?.[0];
                  setAmountShadow(newAmt || "");
                  setAmountCents(newAmt ? Number(newAmt) * 100 : undefined);
                }}
                validate={val => {
                  if (!val) {
                    return translate.common.IsRequired;
                  }
                  const valNumber = Number((Number(val) * 100).toFixed(2));
                  if (valNumber > p.maxAmountCents) {
                    return translate(
                      { defaultMessage: "The Amount cannot exceed {amount}." },
                      { amount: formatMoneyCentsToDollarCentPrettyString(p.maxAmountCents) }
                    );
                  }
                  return "";
                }}
                inputProps={{ type: "number", min: 0 }}
                value={amountShadow}
                style={{ marginTop: 16 }}
              />
              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={translate.common.Note}
                value={note}
                isRequired
                onChange={newVal => {
                  setNote(newVal ?? "");
                }}
              />
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
