import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const MountainBikingBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.mountainBiking as const
} as const;

const MountainBikingBaseWithPlayerObj = {
  ...MountainBikingBaseObj,
  playerId: "" as ScoreKeepingPlayerId
};

export const SKE_mountainBikingObj = {} as const;

export type SKE_mountainBiking = Simplify<Values<NullableToOptional<typeof SKE_mountainBikingObj>>>;
