import {
  AccountId,
  CalendarEntry,
  CalendarEntryGameScrimmage,
  CALENDAR_ENTRY_TYPES,
  Org,
  ORG_EVENT_INVITED_ROLE_OPTIONS,
  ORG_EVENT_ROLE_GROUPS,
  Team,
  Team__StaffTypes,
  GoogleAddress,
  TEAM_SPORT
} from "@ollie-sports/models";
import moment, { Moment } from "moment-timezone";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import _ from "lodash";
import { ObjectKeys } from "../utils";
import { compute } from "..";

export const prettyCalendarEntryTitleStrings = _.memoize(
  (calEntry: CalendarEntry, locale: string, teamSport: TEAM_SPORT) => {
    const date = moment(calEntry.startDateTime);

    const prettyEventTypeStrings = compute.team.getCalendarEntryTypeDisplayForTeamSport({ serverLocale: locale, teamSport });

    switch (calEntry.calendarEntryType) {
      case CALENDAR_ENTRY_TYPES.practice:
        const dayOfWeek = dateFormatters.dayOfWeek(date, locale);
        return {
          type: prettyEventTypeStrings.practice,
          title: translate(
            {
              defaultMessage: "{dayOfWeek} Practice",
              description: "Title for practice event in the users calendar E.g. Thursday Practice",
              serverLocale: locale
            },
            { dayOfWeek }
          ),
          contextualTitle: prettyEventTypeStrings.practice
        };

      case CALENDAR_ENTRY_TYPES.game:
        return {
          type: prettyEventTypeStrings.game,
          title: calEntry.opponentName,
          contextualTitle: translate(
            {
              defaultMessage: "{eventTypeString} vs {opponentName}",
              description: "The title for an game event in the user's calendar",
              serverLocale: locale
            },
            { opponentName: calEntry.opponentName, eventTypeString: prettyEventTypeStrings.game }
          )
        };
      case CALENDAR_ENTRY_TYPES.scrimmage:
        return {
          type: prettyEventTypeStrings.scrimmage,
          title: calEntry.opponentName,
          contextualTitle: translate(
            { defaultMessage: "{eventTypeString} vs {opponentName}", serverLocale: locale },
            { opponentName: calEntry.opponentName, eventTypeString: prettyEventTypeStrings.scrimmage }
          )
        };
      case CALENDAR_ENTRY_TYPES.other:
      default:
        return {
          type: prettyEventTypeStrings.other,
          title: calEntry.title,
          contextualTitle: calEntry.title
        };
    }
  },
  (a, locale) => {
    return a.startDateTime + a.calendarEntryType + a.opponentName + a.title + locale;
  }
);

export const prettyCalendarEntryDateStrings = _.memoize(
  (calEntry: Pick<CalendarEntry, "arrivalDateTime" | "startDateTime" | "endDateTime" | "timezone">, locale: string) => {
    const date = moment.tz(calEntry.startDateTime, calEntry.timezone);
    const endDate = moment.tz(calEntry.endDateTime, calEntry.timezone);
    const arriveDate = moment.tz(calEntry.arrivalDateTime, calEntry.timezone);

    let dateInMyZone = moment.tz(calEntry.startDateTime, calEntry.timezone);
    let endDateInMyZone = moment.tz(calEntry.endDateTime, calEntry.timezone);
    let arriveDateInMyZone = moment.tz(calEntry.arrivalDateTime, calEntry.timezone);

    let timezonePostfix = ` ${date.format("z")}`;
    let timezonePostfixInMyZone = "";

    const hasDifferentTimezones = date.utcOffset() !== moment.tz(calEntry.startDateTime, moment.tz.guess(true)).utcOffset();

    if (hasDifferentTimezones) {
      const guessedZone = moment.tz.guess(true);
      dateInMyZone.tz(guessedZone);
      endDateInMyZone.tz(guessedZone);
      arriveDateInMyZone.tz(guessedZone);
      timezonePostfixInMyZone = ` ${dateInMyZone.format("z")}`;
    }

    return {
      get monthDate() {
        return date.format("D");
      },
      get shortMonth() {
        return dateFormatters.shortMonth(date, locale);
      },
      get shortDayOfWeek() {
        return dateFormatters.shortDayOfWeek(date, locale);
      },
      get fullTime() {
        return dateFormatters.dddd_mmmm_d_yyyy_t_tt_a(date, locale) + timezonePostfix;
      },
      get timeInterval() {
        return getIntervalStr(date, endDate, hasDifferentTimezones, locale);
      },
      get arriveOClock() {
        return calEntry.arrivalDateTime && calEntry.arrivalDateTime !== calEntry.startDateTime
          ? dateFormatters.t_tt_a(arriveDate, locale)
          : null;
      },
      get postfix() {
        return timezonePostfix;
      },
      get differentTimezones() {
        return hasDifferentTimezones;
      },
      get otherZoneTimeInterval() {
        return getIntervalStr(dateInMyZone, endDateInMyZone, hasDifferentTimezones, locale);
      },
      get otherZoneArriveOClock() {
        return calEntry.arrivalDateTime && calEntry.arrivalDateTime !== calEntry.startDateTime
          ? dateFormatters.t_tt_a(arriveDateInMyZone, locale)
          : null;
      },
      get otherZonePostfix() {
        return timezonePostfixInMyZone;
      },
      get shortTime() {
        return dateFormatters.t_tt_a(date, locale) + timezonePostfix;
      },
      get shortDateTime() {
        return dateFormatters.mmm_d_t_tt_a(date, locale);
      },
      get shortTimeWithoutTimezone() {
        return dateFormatters.t_tt_a(date, locale);
      },
      get shortDate() {
        return dateFormatters.dddd_mmmm_d_yyyy(date, locale);
      },
      get endOClock() {
        return calEntry.endDateTime ? dateFormatters.t_tt_a(endDate, locale) : null;
      },
      get endFullTime() {
        return dateFormatters.dddd_mmmm_d_yyyy_t_tt_a(endDate, locale) + timezonePostfix;
      },
      get endMonthDate() {
        return endDate.format("D");
      },
      get endShortMonth() {
        return dateFormatters.shortMonth(endDate, locale);
      },
      get endShortDayOfWeek() {
        return dateFormatters.shortDayOfWeek(endDate, locale);
      },
      get endShortDate() {
        return dateFormatters.dddd_mmmm_d_yyyy(endDate, locale);
      }
    };
  },
  (arg, locale) => {
    return arg.arrivalDateTime + arg.startDateTime + arg.endDateTime + arg.timezone + locale;
  }
);

export function prettyCalendarEntryStrings(calEntry: CalendarEntry, locale: string, teamSport: TEAM_SPORT) {
  const dateStrings = prettyCalendarEntryDateStrings(calEntry, locale);
  const titleStrings = prettyCalendarEntryTitleStrings(calEntry, locale, teamSport);

  return {
    ...dateStrings,
    ...titleStrings
  };
}

export function getIntervalStr(date1: Moment, date2: Moment, showTimezone: boolean, locale: string) {
  const optionalTimezone = showTimezone ? ` ${date1.format("z")}` : "";
  const isMultiDay = date2.isAfter(moment(date1).endOf("day"));
  const startTime = isMultiDay
    ? normalizeTime(dateFormatters.t_tt_a_mmmm_d(date1, locale))
    : normalizeTime(dateFormatters.t_tt_a(date1, locale));
  const endTime = isMultiDay
    ? normalizeTime(dateFormatters.t_tt_a_mmmm_d(date2, locale))
    : normalizeTime(dateFormatters.t_tt_a(date2, locale));
  return translate(
    {
      defaultMessage: "{startTime} to {endTime}{optionalTimezone}",
      description: "A time interval. E.g. 8:30 am to 10:30 am MST",
      serverLocale: locale
    },
    { startTime, endTime, optionalTimezone }
  );
}

//Some niceties for English...
function normalizeTime(str: string) {
  return str.replace(":00", "").replace("p. m.", "pm").replace("a. m.", "am");
}

export function isImminentGame(calendarEntry: CalendarEntry) {
  const earliest = moment.tz(calendarEntry.startDateTime, calendarEntry.timezone).subtract(1, "hours").valueOf();
  const latest = moment.tz(calendarEntry.startDateTime, calendarEntry.timezone).endOf("day").valueOf();

  return Date.now() > earliest && Date.now() < latest;
}

export function isImminentGameUntilAWeekAfter(calendarEntry: CalendarEntry) {
  const earliest = moment.tz(calendarEntry.startDateTime, calendarEntry.timezone).subtract(1, "hours").valueOf();
  const latest = moment.tz(calendarEntry.startDateTime, calendarEntry.timezone).add(7, "days").endOf("day").valueOf();

  return Date.now() > earliest && Date.now() < latest;
}

export function shouldEnableLiveGameModeButton(calendarEntry: CalendarEntry) {
  const earliest = moment.tz(calendarEntry.startDateTime, calendarEntry.timezone).subtract(1, "hours").valueOf();

  return Date.now() > earliest;
}

export function isCalendarEntry2HoursInPast(calendarEntry: CalendarEntry) {
  return moment.tz(calendarEntry.endDateTime, calendarEntry.timezone).valueOf() + 2 * 60 * 60 * 1000 < Date.now();
}

export function isCalendarEntryInPast(calendarEntry: CalendarEntry) {
  return moment.tz(calendarEntry.endDateTime, calendarEntry.timezone).valueOf() < Date.now();
}

export function timeHasPassedSinceScheduledGameStart(calendarEntry: CalendarEntryGameScrimmage, hours: number) {
  return moment.tz(calendarEntry.startDateTime, calendarEntry.timezone).add(hours, "hours").valueOf() < Date.now();
}

export function getOrgRoleBasedEventData(locale: string): Record<
  ORG_EVENT_ROLE_GROUPS,
  {
    label: string;
    includedRoles: { [key in ORG_EVENT_INVITED_ROLE_OPTIONS]?: true };
    tagTexts: string[];
  }
> {
  return {
    all: {
      label: translate.common(locale).EntireClub,
      tagTexts: [translate.common.All],
      includedRoles: {
        all: true,
        orgAdmin: true
      }
    },
    clubAdmins: {
      label: translate.common(locale).ClubAdmins,
      tagTexts: [translate.common(locale).ClubAdmins],
      includedRoles: {
        orgAdmin: true
      }
    },
    coaches: {
      label: translate.common(locale).AllCoaches,
      tagTexts: [translate.common(locale).Coaches],
      includedRoles: {
        assistantCoach: true,
        headCoach: true,
        orgAdmin: true
      }
    },
    headCoaches: {
      label: translate.common(locale).HeadCoaches,
      tagTexts: [translate.common(locale).HeadCoaches],
      includedRoles: {
        headCoach: true,
        orgAdmin: true
      }
    },
    teamAdmins: {
      label: translate.common(locale).TeamAdmins,
      tagTexts: [translate.common(locale).TeamAdmins],
      includedRoles: {
        teamAdmin: true,
        orgAdmin: true
      }
    },
    teamStaff: {
      label: translate.common(locale).CoachesAndTeamAdmins,
      tagTexts: [translate.common(locale).Coaches, translate.common.TeamAdmins],
      includedRoles: {
        assistantCoach: true,
        headCoach: true,
        teamAdmin: true,
        staffMember: true,
        orgAdmin: true
      }
    }
  };
}

export function isSelfAccountInvitedToOrgEvent(p: {
  selfAccountId: AccountId;
  selfTeams: Team[];
  org: Org;
  calendarEntry: CalendarEntry;
  locale: string;
}) {
  if (!p.calendarEntry.orgEventOrgId) {
    return false;
  }
  const selfTeamsInTheOrg = p.selfTeams.filter(t => t.orgId === p.org.id);
  const orgEventRoleGroup: ORG_EVENT_ROLE_GROUPS = p.calendarEntry.orgEventRoleGroup ?? ORG_EVENT_ROLE_GROUPS.all;
  const invitedRoles = ObjectKeys(getOrgRoleBasedEventData(p.locale)[orgEventRoleGroup].includedRoles).filter(
    role => (getOrgRoleBasedEventData(p.locale)[orgEventRoleGroup].includedRoles[role] = true)
  );
  const isOrgAdmin = !!p.org.accounts[p.selfAccountId]?.exists;
  const isOnTeamInOrg = selfTeamsInTheOrg.find(t => t.orgId === p.org.id && !!t.accounts[p.selfAccountId]?.exists);
  const isPartOfOrg = isOrgAdmin || isOnTeamInOrg;

  if (invitedRoles.includes("all") && isPartOfOrg) {
    return true;
  }
  if (invitedRoles.includes("orgAdmin") && isOrgAdmin) {
    return true;
  }
  const isHeadCoach = !!selfTeamsInTheOrg.find(t => t.accounts[p.selfAccountId]?.staffTitle === Team__StaffTypes.headCoach);
  console.log(isHeadCoach);
  if (invitedRoles.includes(Team__StaffTypes.headCoach) && isHeadCoach) {
    return true;
  }
  const isAssistantCoach = !!selfTeamsInTheOrg.find(
    t => t.accounts[p.selfAccountId]?.staffTitle === Team__StaffTypes.assistantCoach
  );
  if (invitedRoles.includes(Team__StaffTypes.assistantCoach) && isAssistantCoach) {
    return true;
  }
  const isTeamAdmin = !!selfTeamsInTheOrg.find(t => t.accounts[p.selfAccountId]?.staffTitle === Team__StaffTypes.teamAdmin);
  if (invitedRoles.includes(Team__StaffTypes.teamAdmin) && isTeamAdmin) {
    return true;
  }
  const isTeamStaff = !!selfTeamsInTheOrg.find(t => t.accounts[p.selfAccountId]?.staffTitle === Team__StaffTypes.staffMember);
  if (invitedRoles.includes(Team__StaffTypes.staffMember) && isTeamStaff) {
    return true;
  }
  return false;
}

export function getVerboseLocationString(location: GoogleAddress, locale: string) {
  const prettyAddress = location.fullAddress ?? location.formattedAddress;
  const locationString =
    location.venue && prettyAddress
      ? translate(
          {
            defaultMessage: "{venue} at {address}",
            description: 'A sentence fragment describing a venue and its address, like "Pizza Pie Cafe at 1100 Main St"',
            serverLocale: locale
          },
          { venue: location.venue, address: prettyAddress }
        )
      : location.venue;

  return locationString ?? null;
}

export function getLocationURL(loc: GoogleAddress) {
  const { fullAddress, venue, coords, formattedAddress } = loc;
  const coordStr = coords ? `${coords.lat},${coords.long}` : "";
  const merged = [venue, formattedAddress ?? fullAddress].filter(a => a).join("\n");
  const escaped = merged.replace(/[ \n]/g, "+");
  return escaped ? `https://www.google.com/maps/dir//${escaped}${coords ? `/@${coordStr}` : ""}` : null;
}

// i18n certified - complete
