import {
  OrgCoupon,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgInvoice__Registration,
  OrgPayment,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceCreditApplicationMethod,
  OrgPaymentPlan,
  OrgPaymentType,
  PaymentMethodType
} from "@ollie-sports/models";
import _ from "lodash";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import {
  DistributiveOmit,
  DistributivePick,
  IndividualOrgInvoiceDetails,
  ObjectKeys,
  filterOrgPaymentInvoices,
  generateOrgPaymentId,
  getIndividualOrgInvoiceAmountDetails
} from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";
import shortid from "shortid";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

type BaseProps = {
  selfAccountId: string;
  note: string;
  amountCents: number | "all-remaining";
  orgId: string;
  parentOrgInvoiceId: OrgInvoiceId; //Could be equal to orgInvoiceId
};

export async function orgPayment__server__addOrgPaymentInvoiceCredits(
  p:
    | ({
        type: "all-outstanding"; //Should be applied across all outstanding invoices
        applicationMethod: OrgPaymentInvoiceCreditApplicationMethod;
      } & BaseProps)
    | ({
        type: "single"; //The credits are applied only to orgInvoiceId
        orgInvoiceId: string;
      } & BaseProps)
) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const parentProm = h.OrgInvoice.getDoc(p.parentOrgInvoiceId);

  const [orgInvoice, parentOrgInvoice, org, allChildrenInvoices, allOrgPaymentsConnectedToParent] = await Promise.all([
    (p.type === "all-outstanding" ? parentProm : h.OrgInvoice.getDoc(p.orgInvoiceId)) as Promise<OrgInvoiceParent>,
    parentProm,
    h.Org.getDoc(p.orgId),
    h.OrgInvoice.query({ where: [{ parentOrgInvoiceId: ["==", p.parentOrgInvoiceId] }] }).then(a => a.docs as OrgInvoiceChild[]),
    h.OrgPayment.query({ where: [{ invoiceGroupId: ["==", p.parentOrgInvoiceId] }] }).then(a => a.docs as OrgPayment[])
  ]);

  if (!org || !orgInvoice || !parentOrgInvoice) {
    throw new Error("Invalid org id or org invoice id or org invoice parent id!");
  }

  if (!org.accounts[p.selfAccountId]?.permissions.manageFinances) {
    throw new Error("Does not have permission to issue credits!");
  }

  const outstandingInvoices = [parentOrgInvoice, ...allChildrenInvoices].filter(a => !a.thisInvoicePaidInFullDateMS);
  const invoicesToBeAppliedCredits = p.type === "all-outstanding" ? outstandingInvoices : [orgInvoice];

  if (
    orgInvoice.orgId !== p.orgId ||
    ("parentOrgInvoiceId" in orgInvoice && orgInvoice.parentOrgInvoiceId !== p.parentOrgInvoiceId) ||
    (p.type === "single" && orgInvoice.thisInvoicePaidInFullDateMS) ||
    outstandingInvoices.length === 0 ||
    !p.amountCents
  ) {
    throw new Error("Invalid relationships between inputs!");
  }

  const baseCredit: Omit<
    OrgPaymentInvoiceCredit,
    "id" | "createdAtMS" | "groupingId" | "amountCents" | "invoiceId" | "invoiceGroupId"
  > = {
    type: OrgPaymentType.invoiceCredit,
    appliedByAccountId: p.selfAccountId,
    note: p.note,
    orgId: parentOrgInvoice.orgId,
    status: "succeeded",
    playerBundleId: parentOrgInvoice.playerBundleId
  };

  const allCreditsToAdd: Omit<OrgPaymentInvoiceCredit, "id" | "createdAtMS" | "groupingId">[] = [];

  const oiDetailsById: Record<string, IndividualOrgInvoiceDetails> = outstandingInvoices.reduce((acc, oi) => {
    acc[oi.id] = getIndividualOrgInvoiceAmountDetails({
      orgInvoice: oi,
      orgPayments: filterOrgPaymentInvoices(allOrgPaymentsConnectedToParent).filter(op => op.invoiceId === oi.id),
      paymentMethodType: PaymentMethodType.card // Doesn't matter
    });
    return acc;
  }, {} as Record<string, IndividualOrgInvoiceDetails>);

  const totalCreditAmountCents =
    p.amountCents === "all-remaining"
      ? outstandingInvoices.reduce((a, b) => a + oiDetailsById[b.id].remainingAmount, 0)
      : p.amountCents;

  if (invoicesToBeAppliedCredits.length === 1) {
    const thisInvoice = invoicesToBeAppliedCredits[0];

    allCreditsToAdd.push({
      ...baseCredit,
      amountCents: Math.min(totalCreditAmountCents, oiDetailsById[thisInvoice.id].remainingAmount),
      invoiceGroupId: parentOrgInvoice.id,
      invoiceId: thisInvoice.id
    });
  } else if (invoicesToBeAppliedCredits.length > 1) {
    if (p.type === "single") {
      throw new Error("Should not have multiple!");
    }

    switch (p.applicationMethod) {
      case OrgPaymentInvoiceCreditApplicationMethod.equal:
        const totalRemainingAmount = _.sum(outstandingInvoices.map(oi => oiDetailsById[oi.id].remainingAmount));
        if (totalRemainingAmount === 0) {
          // Shouldn't happen, just guarding agains dividing by 0
          return;
        }
        const creditPercentInRelationToTotalRemainingAmount = totalCreditAmountCents / totalRemainingAmount;
        _.orderBy(outstandingInvoices, a => a.dueDateMS, "asc").forEach(oi => {
          const thisInvoiceAmountCents = Math.floor(
            oiDetailsById[oi.id].remainingAmount * creditPercentInRelationToTotalRemainingAmount
          );

          allCreditsToAdd.push({
            ...baseCredit,
            amountCents: Math.min(thisInvoiceAmountCents, oiDetailsById[oi.id].remainingAmount),
            invoiceGroupId: oi.invoiceGroupId,
            invoiceId: oi.id
          });
        });
        const totalAmountApplied = _.sum(allCreditsToAdd.map(c => c.amountCents));
        let centsRemainingToApply = totalCreditAmountCents - totalAmountApplied;
        let currentIndex = 0;
        while (centsRemainingToApply > 0) {
          allCreditsToAdd[currentIndex].amountCents = allCreditsToAdd[currentIndex].amountCents + 1;
          centsRemainingToApply = centsRemainingToApply - 1;
          currentIndex = currentIndex === allCreditsToAdd.length - 1 ? 0 : currentIndex + 1;
        }

        break;
      case OrgPaymentInvoiceCreditApplicationMethod.beginning:
      case OrgPaymentInvoiceCreditApplicationMethod.end:
        let amountCentsToApplyRemaining = totalCreditAmountCents;
        const sortOrder = p.applicationMethod === OrgPaymentInvoiceCreditApplicationMethod.beginning ? "asc" : "desc";
        _.orderBy(outstandingInvoices, a => a.dueDateMS, sortOrder).forEach(oi => {
          if (amountCentsToApplyRemaining) {
            const remainingAmount = oiDetailsById[oi.id].remainingAmount;
            if (remainingAmount) {
              const amountCentsToApplyToThisCredit = Math.min(remainingAmount, amountCentsToApplyRemaining);
              amountCentsToApplyRemaining = amountCentsToApplyRemaining - amountCentsToApplyToThisCredit;
              allCreditsToAdd.push({
                ...baseCredit,
                amountCents: amountCentsToApplyToThisCredit,
                invoiceGroupId: oi.invoiceGroupId,
                invoiceId: oi.id
              });
            }
          }
        });
        break;
    }
  }

  const nowMS = Date.now();

  const batchTasks: BatchTask[] = [];

  //Initialize invoice updates
  const orgInvoiceUpdates: Record<
    OrgInvoiceId,
    DistributivePick<
      OrgInvoice,
      | "derivedTotalAmountPaidCentsBeforeAllFees"
      | "thisInvoicePaidInFullDateMS"
      | "amountDueCents"
      | "lateFeeCentsToBeIssuedIfLate"
      | "dueDateMS"
    >
  > = outstandingInvoices.reduce((acc, oi) => {
    acc[oi.id] = {
      derivedTotalAmountPaidCentsBeforeAllFees: oi.derivedTotalAmountPaidCentsBeforeAllFees,
      thisInvoicePaidInFullDateMS: oi.thisInvoicePaidInFullDateMS,
      amountDueCents: oi.amountDueCents,
      dueDateMS: oi.dueDateMS,
      lateFeeCentsToBeIssuedIfLate: oi.lateFeeCentsToBeIssuedIfLate
    };
    return acc;
  }, {} as Record<OrgInvoiceId, Pick<OrgInvoice, "derivedTotalAmountPaidCentsBeforeAllFees" | "thisInvoicePaidInFullDateMS" | "amountDueCents" | "lateFeeCentsToBeIssuedIfLate" | "dueDateMS">>);

  //1. Apply all credits to related invoice update objects, (2) Add credit batch tasks
  const groupingId = shortid();
  for (let i = 0; i < allCreditsToAdd.length; i++) {
    const opc = allCreditsToAdd[i];
    const prevInvoiceDetails = orgInvoiceUpdates[opc.invoiceId];

    const prevPaidAmount = oiDetailsById[opc.invoiceId].creditAmount + oiDetailsById[opc.invoiceId].paidAmount;
    const newPaidAmount = prevPaidAmount + opc.amountCents;
    orgInvoiceUpdates[opc.invoiceId] = {
      ...prevInvoiceDetails,
      derivedTotalAmountPaidCentsBeforeAllFees: Math.min(newPaidAmount, prevInvoiceDetails.amountDueCents), // This field should not exceed the amountDue because it doesn't include fees
      thisInvoicePaidInFullDateMS: prevInvoiceDetails.thisInvoicePaidInFullDateMS
        ? prevInvoiceDetails.thisInvoicePaidInFullDateMS
        : newPaidAmount >= oiDetailsById[opc.invoiceId].totalAmount // If we have covered the base amount PLUS late fees, then mark it as paid
        ? nowMS
        : 0
    };

    const newCredit: OrgPaymentInvoiceCredit = {
      ...opc,
      createdAtMS: nowMS,
      id: generateOrgPaymentId(),
      groupingId
    };
    batchTasks.push(
      await h.OrgPayment.add(
        {
          doc: newCredit
        },
        { returnBatchTask: true }
      )
    );
  }

  // Write invoice update batch tasks
  for (let j = 0; j < ObjectKeys(orgInvoiceUpdates).length; j++) {
    const invoiceId = ObjectKeys(orgInvoiceUpdates)[j];
    batchTasks.push(
      await h.OrgInvoice.update(
        {
          id: invoiceId,
          doc: {
            derivedTotalAmountPaidCentsBeforeAllFees: orgInvoiceUpdates[invoiceId].derivedTotalAmountPaidCentsBeforeAllFees,
            thisInvoicePaidInFullDateMS: orgInvoiceUpdates[invoiceId].thisInvoicePaidInFullDateMS
          }
        },
        { returnBatchTask: true }
      )
    );
  }

  //Update parent invoice
  batchTasks.push(
    await h.OrgInvoice.update(
      {
        id: p.parentOrgInvoiceId,
        doc: {
          derivedTotalAmountPaidCentsIncludingChildrenInvoices:
            allOrgPaymentsConnectedToParent.reduce((a, b) => a + b.amountCents, 0) + totalCreditAmountCents,
          thisInvoicePaidInFullDateMS: nowMS
        }
      },
      { returnBatchTask: true }
    )
  );

  await h._BatchRunner.executeBatch(batchTasks);
}

orgPayment__server__addOrgPaymentInvoiceCredits.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};
