import { translate } from "@ollie-sports/i18n";
import { Team__StaffTypes, Team__Account, TEAM_PERMISSIONS, TEAM_SPORT } from "@ollie-sports/models";

export const PRETTY_TEAM_SPORT: (locale: string) => Record<TEAM_SPORT, string> = locale => ({
  [TEAM_SPORT.americanFootball]: translate.common(locale).AmericanFootball,
  [TEAM_SPORT.baseball]: translate.common(locale).Baseball,
  [TEAM_SPORT.basketball]: translate.common(locale).Basketball,
  [TEAM_SPORT.hockey]: translate.common(locale).Hockey,
  [TEAM_SPORT.lacrosse]: translate.common(locale).Lacrosse,
  [TEAM_SPORT.other]: translate.common(locale).OtherSport,
  [TEAM_SPORT.soccer]: translate.common(locale).Soccer,
  [TEAM_SPORT.softball]: translate.common(locale).Softball,
  [TEAM_SPORT.volleyball]: translate.common(locale).Volleyball,
  [TEAM_SPORT.waterPolo]: translate.common(locale).WaterPolo,
  [TEAM_SPORT.mountainBiking]: translate.common(locale).MountainBiking
});

export const Team__StaffPresets = (
  locale: string
): Record<Team__StaffTypes, { staffTitle: string; additionalPermissions: Team__Account["additionalPermissions"] }> => ({
  [Team__StaffTypes.headCoach]: {
    staffTitle: translate.common(locale).HeadCoach,
    additionalPermissions: {
      [TEAM_PERMISSIONS.manageEvents]: true,
      [TEAM_PERMISSIONS.manageRoster]: true,
      [TEAM_PERMISSIONS.recordStats]: true,
      [TEAM_PERMISSIONS.viewIndividualStats]: true,
      [TEAM_PERMISSIONS.manageRolesAndPermissions]: true,
      [TEAM_PERMISSIONS.squad_a_staff]: true,
      [TEAM_PERMISSIONS.squad_b_staff]: true,
      [TEAM_PERMISSIONS.squad_c_staff]: true
    }
  },
  [Team__StaffTypes.assistantCoach]: {
    staffTitle: translate.common(locale).AssistantCoach,
    additionalPermissions: {
      [TEAM_PERMISSIONS.manageEvents]: true,
      [TEAM_PERMISSIONS.manageRoster]: true,
      [TEAM_PERMISSIONS.recordStats]: true,
      [TEAM_PERMISSIONS.viewIndividualStats]: true,
      [TEAM_PERMISSIONS.manageRolesAndPermissions]: true,
      [TEAM_PERMISSIONS.squad_a_staff]: true,
      [TEAM_PERMISSIONS.squad_b_staff]: true,
      [TEAM_PERMISSIONS.squad_c_staff]: true
    }
  },
  [Team__StaffTypes.teamAdmin]: {
    staffTitle: translate.common(locale).TeamAdmin,
    additionalPermissions: {
      [TEAM_PERMISSIONS.manageEvents]: true,
      [TEAM_PERMISSIONS.manageRoster]: true,
      [TEAM_PERMISSIONS.recordStats]: true,
      [TEAM_PERMISSIONS.manageRolesAndPermissions]: true,
      [TEAM_PERMISSIONS.squad_a_staff]: true,
      [TEAM_PERMISSIONS.squad_b_staff]: true,
      [TEAM_PERMISSIONS.squad_c_staff]: true
    }
  },
  [Team__StaffTypes.staffMember]: {
    staffTitle: translate.common(locale).StaffMember,
    additionalPermissions: {
      [TEAM_PERMISSIONS.manageEvents]: true,
      [TEAM_PERMISSIONS.manageRoster]: true,
      [TEAM_PERMISSIONS.recordStats]: true,
      [TEAM_PERMISSIONS.manageRolesAndPermissions]: true,
      [TEAM_PERMISSIONS.squad_a_staff]: true,
      [TEAM_PERMISSIONS.squad_b_staff]: true,
      [TEAM_PERMISSIONS.squad_c_staff]: true
    }
  }
});
